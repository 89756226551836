import { Button, Table, Modal, Input } from "antd";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Formik, Field, Form } from "formik";
import { useSensorStore } from "../store/sensorStore";
import { useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";

function ManageSensor() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sensors, fetchAllSensor, createSensor, deleteSensor } =
    useSensorStore();
  const { Search } = Input;
  const searchItems = searchParams.get("searchItems");

  const debouncedFetchAllSensor = _debounce((value) => {
    fetchAllSensor({ searchItems: value });
  }, 1000);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchParams((params) => {
      params.set("searchItems", value);
      return params;
    });
    debouncedFetchAllSensor(value);
  };

  const columns = [
    {
      title: "Sensor",
      dataIndex: "Sensor",
      key: "Sensor",
      render: (value, record) => `${record?.sensor_name || "-"}`,
    },

    // {
    //     title: "Action",
    //     key: "action",
    //     render: (_, record) => (
    //         <div className="flex gap-2 items-center">
    //             <Button danger onClick={() => handleConfirmDelete(record._id)}>
    //                 Delete
    //             </Button>
    //         </div>
    //     ),
    // },
  ];

  //function for modal create sensor
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreateSensor = async (value, actions) => {
    const d = {
      sensor_name: value.sensor_name || "",
    };

    createSensor(d);
    setIsModalOpen(false);
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Success Create Sensor",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteUser = async (id) => {
    try {
      deleteSensor(id);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success delete sensor",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this sensor",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    });
    if (result.isConfirmed) {
      handleDeleteUser(id);
    }
  };

  useEffect(() => {
    return () => debouncedFetchAllSensor.cancel();
  }, [debouncedFetchAllSensor]);

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  return (
    <div>
      <p className="text-xl font-bold">Manage Sensor</p>
      <div className="flex mb-6 mt-2 justify-between">
        {/* <div className="flex gap-2">
                    <Button type="primary" onClick={showModal}>Create new sensor</Button>
                </div> */}

        <Search
          placeholder="Search sensor"
          onChange={onSearchInputChange}
          style={{
            width: 180,
          }}
        />
      </div>

      <Table
        dataSource={sensors}
        columns={columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Create New Sensor"
        open={isModalOpen}
        okText="Create"
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          initialValues={{
            sensor_name: "",
          }}
          onSubmit={(value, actions) => {
            handleCreateSensor(value, actions);
          }}
        >
          {(props) => {
            return (
              <div>
                <Form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" />
                  <div className="my-6">
                    <label
                      htmlFor="sensor_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Sensor Name
                    </label>
                    <div className="mt-2">
                      <Field
                        id="sensor_name"
                        name="sensor_name"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Sensor Name"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>

                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default ManageSensor;
