import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../../store/loggerStore";
import { useCompanyStore } from "../../store/companyStore";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button, Table } from "antd";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../../components/DropdownItems";
import DownloadPdf from "../../components/DownloadPDF";
import { useSensorStore } from "../../store/sensorStore";

function TablePage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  const { loggerDataPerMenit, fetchDataPerMenit } = useLoggerDataStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const loggerSensor = [];
  const companyId = loggerPerUser[0]?.company_id;
  // const uidLogger = loggerPerUser[0]?.uid_logger;
  // const uidLogger = loggerPerUser[0]?.uid;
  const idStasiunLogger = loggerPerUser[0]?.idstasiun;
  const { RangePicker } = DatePicker;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const { CSVDownloader, Type } = useCSVDownloader();
  const [loading, setLoading] = useState(true);
  const searchItems = searchParams.get("searchItems");
  // loggerDataPerMenit.sort((a, b) => a.datetime - b.datetime);

  // to check what sensors are active in a logger
  if ((loggerPerUser[0]?.sensors?.length > 0) & (sensors.length !== 0)) {
    const dataSensor = loggerPerUser[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  } else if (
    (loggerPerUser[0]?.sensors?.length === 0) &
    (sensors.length !== 0)
  ) {
    const dataSensor = loggerPerUser[0]?.industry_type_id[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }

  const isSensorNameIncluded = (sensorName) => {
    if (loggerSensor.length > 0) {
      return loggerSensor[0].includes(sensorName);
    }
  };
  //

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const downloadedDataLogger = loggerDataPerMenit.map((data) => {
    return {
      logger_name: loggerPerUser[0]?.logger_name,
      date: convertDate(data.datetime),
      datetime: data.datetime,
      suhu: data.suhu,
      // dhl: data.dhl,
      tds: data.tds,
      do: data.do,
      ph: data.ph,
      turbidity: data.turbidity,
      kedalaman: data.kedalaman,
      nitrat: data.nitrat,
      amonia: data.amonia,
      cod: data.cod,
      bod: data.bod,
      tss: data.tss,
      // swSG: data.swSG,
      // salinitas: data.salinitas,
      // orp: data.orp
    };
  });

  const downloadXLSX = () => {
    const xlsxData = downloadedDataLogger;

    // const headers = ["Name", "Age", "City"];

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "report.xlsx");
  };

  // const thresholdsData = [];
  // if (company.id_threshold) {
  //   thresholdsData.push(company?.id_threshold);
  // } else {
  //   thresholdsData.push(company?.industry_type_id?.id_threshold);
  // }

  const columns = [
    {
      title: "Receive Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) => convertDate(record.datetime),
    },
    isSensorNameIncluded("suhu")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">Suhu</p>
              <sub>(°C)</sub>
            </div>
          );
        },
        dataIndex: "suhu",
        key: "suhu",

        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    // isSensorNameIncluded("dhl")
    //   ?
    //   {
    //     title: () => {
    //       return (
    //         <div>
    //           <p className="m-0">Daya Hantar listrik</p>
    //           <sub>(μS/cm)</sub>
    //         </div>
    //       );
    //     },
    //     dataIndex: "dhl",
    //     key: "dhl",
    //     render: (value, record) => value.toFixed(2) || "0",
    //   } : {},

    isSensorNameIncluded("tds")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">TDS</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "tds",
        key: "tds",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("do")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">DO</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "do",
        key: "do",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("pH")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">pH</p>
            </div>
          );
        },
        dataIndex: "ph",
        key: "ph",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("turbidity")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">Turbidity</p>
              <sub>(NTU)</sub>
            </div>
          );
        },
        dataIndex: "turbidity",
        key: "turbidity",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("kedalaman")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">Kedalaman</p>
              <sub>(m)</sub>
            </div>
          );
        },
        dataIndex: "kedalaman",
        key: "kedalaman",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("nitrat")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">Nitrat</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "nitrat",
        key: "nitrat",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("amonia")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">Amonia</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "amonia",
        key: "amonia",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("cod")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">COD</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "cod",
        key: "cod",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("bod")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">BOD</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "bod",
        key: "bod",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    isSensorNameIncluded("tss")
      ?
      {
        title: () => {
          return (
            <div>
              <p className="m-0">TSS</p>
              <sub>(mg/L)</sub>
            </div>
          );
        },
        dataIndex: "tss",
        key: "tss",
        render: (value, record) => value.toFixed(2) || "0",
      } : {},

    // isSensorNameIncluded("swsg")
    //   ?
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">SwSG</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "swsg",
    //   key: "swsg",
    //   render: (value, record) => value.toFixed(2) || "0",
    // } :{},
    // 
    // isSensorNameIncluded("salinitas")
    //   ?
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">Salinitas</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "salinitas",
    //   key: "salinitas",
    //   render: (value, record) => value.toFixed(2) || "0",
    // } :{},
    // 
    // isSensorNameIncluded("orp")
    // ?
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">ORP</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "orp",
    //   key: "orp",
    //   render: (value, record) => value.toFixed(2) || "0",
    // } : {},
  ];

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
      const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      // setLoading(true)

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });

      // setLoading(false)
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  const downloadItems = [
    {
      key: "1",
      label: (
        <p className="m-0">
          <DownloadPdf data={downloadedDataLogger} />
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          <CSVDownloader
            type={Type}
            filename={"filename"}
            bom={true}
            config={{
              delimiter: ";",
            }}
            data={downloadedDataLogger}
            className="text-slate-800"
          >
            CSV
          </CSVDownloader>
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0" onClick={downloadXLSX}>
          XLSX
        </p>
      ),
    },
  ];

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (loggerPerUser) {
      fetchLoggerByUserId();
    }
  }, [loggerPerUser._id]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    // if (loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser[0]?.uid) {
    if (loggerPerUser[0]?.idstasiun) {
      fetchDataPerMenit({ idStasiunLogger, startDate, endDate });
      setLoading(false)
    }
  }, [idStasiunLogger, startDate, endDate]);

  return (
    <>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center">
          {/* <Spin className="" size="large" /> */}
          <p className="text-blue-400 m-0 text-lg">Loading...</p>

        </div>
      ) : (
        <>
          {/* <p className="text-xl font-bold text-center m-0">
            {loggerPerUser[0]?.logger_name || "-"}
          </p>
          <p className="text-center text-slate-400 ">
            {loggerPerUser[0]?.logger_address || ""}
          </p> */}

          <div>
            <p className="font-semibold text-center text-lg m-0 mb-3  text-slate-500">
              Data Logger
            </p>
            <div className="flex gap-2 flex-wrap justify-center">
              <RangePicker
                className="mb-4"
                onChange={onChange}
                allowClear={true}
                // showTime={{
                //   format: "HH:mm",
                // }}
                format="DD/MM/YYYY HH:mm"
              />
              <Button onClick={onClear} type="primary">
                Reset Date
              </Button>

              <DropdownItems items={downloadItems} title="Download" />
            </div>
          </div>

          <div className="">
            <Table
              dataSource={loggerDataPerMenit}
              columns={columns}
              pagination={{ pageSize: 20 }}
              className="text-5xl"
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default TablePage;
