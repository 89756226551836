import { Button, Table, Modal, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../store/loggerStore";
import { useCompanyStore } from "../store/companyStore";
import Swal from "sweetalert2";
import moment from "moment";
import { useUserStore } from "../store/userStore";
import { USER } from "../helpers/constant";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import _debounce from "lodash/debounce";

function Logger() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const { loggers, fetchLogger, createLogger, deleteLogger } = useLoggerStore();
  const { companies, fetchDataCompany } = useCompanyStore();
  const { allIndustryTypes, fetchAllIndustryTypes } = useIndustryTypeStore();
  const { users, fetchAllUser } = useUserStore();
  const [selectedIndustryType, setSelectedIndustryType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formNewLogger, setFormNewLogger] = useState({
    company_id: "",
    user_id: "",
    logger_name: "",
    logger_address: "",
  });
  const [dataUser, setDataUser] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [file, setFile] = useState();
  const [isAccept, setIsAccept] = useState(false);
  const { Search } = Input;
  const searchItems = searchParams.get("searchItems");

  // console.log({loggers})

  const debouncedFetchAllLogger = _debounce((value) => {
    fetchLogger({ searchItems: value });
  }, 1000);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchParams((params) => {
      params.set("searchItems", value);
      return params;
    });
    debouncedFetchAllLogger(value);
  };

  //add industry type
  const onImageUploadHandler = (e) => {
    const fileDetail = e.target.files[0];
    const fileFormat = fileDetail.type.split("/")[1];

    if (fileDetail.size > 1024 * 1024) {
      setIsAccept(false);
    } else if (
      fileFormat === "jpeg" ||
      fileFormat === "png" ||
      fileFormat === "jpg"
    ) {
      setIsAccept(true);
      setFile(fileDetail);
      let preview = document.getElementById("loggerImage");
      preview.src = URL.createObjectURL(e.target.files[0]);
    } else {
      setIsAccept(false);
    }

    setImagePreview(URL.createObjectURL(e.target.files[0]));
    URL.revokeObjectURL(imagePreview);
  };

  const onClickSubmitHandler = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("company_id", formNewLogger.company_id);
    formData.append("industry_type_id", selectedIndustryType || "");
    formData.append("user_id", formNewLogger.user_id);
    formData.append("logger_name", formNewLogger.logger_name);
    formData.append("logger_address", formNewLogger.logger_address);

    if (
      formNewLogger.company_id !== undefined &&
      formNewLogger.company_id !== ""
    ) {
      createLogger(formData);
      setIsLoading(false);
      setImagePreview("");
      setIsModalOpen(false);
    }
  };

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const columns = [
    {
      title: "Logger Name",
      dataIndex: "logger_name",
      key: "logger_name",
    },
    {
      title: "ID Stasiun Logger",
      dataIndex: "idstasiun",
      key: "idstasiun",
    },
    // {
    //   title: "UID Logger",
    //   dataIndex: "uid",
    //   key: "uid",
    // },
    {
      title: "Industry Type",
      dataIndex: "industry_type",
      key: "industry_type",
      render: (value, record) =>
        `${record?.industry_type[0]?.industry_type || "-"}`,
    },
    {
      title: "Created Logger Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) => convertDate(record.created_date),
      // render: (value, record) =>
      //   convertDate(record?.log_data[record?.log_data?.length - 1]?.datetime) ||
      //   "No Data Received",
    },

    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Suhu</p>
            <sub>(°C)</sub>
          </div>
        );
      },
      dataIndex: "suhu",
      key: "suhu",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.suhu || "-",
    },
    // {
    //   title: () => {
    //     return (
    //       <div className="">
    //         <p className="m-0">Daya Hantar Listrik</p>
    //         <sub>
    //           (μS/cm)
    //         </sub>
    //       </div>
    //     );
    //   },
    //   dataIndex: "dhl",
    //   key: "dhl",
    //   render: (value, record) =>
    //     record?.log_data[record?.log_data?.length - 1]?.dhl || "-",
    // },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TDS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tds",
      key: "tds",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.tds || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">DO</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "do",
      key: "do",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.do || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "ph",
      key: "ph",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.ph || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Turbidity</p>
            <sub>(NTU)</sub>
          </div>
        );
      },
      dataIndex: "turbidity",
      key: "turbidity",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.turbidity || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Kedalaman</p>
            <sub>(m)</sub>
          </div>
        );
      },
      dataIndex: "kedalaman",
      key: "kedalaman",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.kedalaman || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Nitrat</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "nitrat",
      key: "nitrat",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.nitrat || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Amonia</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "amonia",
      key: "amonia",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.amonia || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "cod",
      key: "cod",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.cod || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">BOD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.bod || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.tss || "-",
    },

    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">SwSG</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "swsg",
    //     key: "swsg",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.swsg || "-",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Salinitas</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "salinitas",
    //     key: "salinitas",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.salinitas || "-",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">ORP</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "orp",
    //     key: "orp",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.orp || "-",
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Link to={`/logger/${record._id}`}>
            <Button className="border-blue-500 border-1 text-blue-500 ">
              View Detail
            </Button>
          </Link>
          {["admin", "developer"].includes(dataUser?.role) && (
            <Button danger onClick={() => handleConfirmDelete(record._id)}>
              Delete
            </Button>
          )}
        </div>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  //function for modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async (data) => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setImagePreview("");
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};
  const onChange = (value, label) => {
    setFormNewLogger((prev) => ({ ...prev, company_id: value }));
  };

  const handleDeleteCompany = async (id) => {
    try {
      deleteLogger(id);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success delete logger",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this question",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    });
    if (result.isConfirmed) {
      handleDeleteCompany(id);
    }
  };

  //function for select user for a logger
  const filterOptionUser = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearchUser = (value) => {};
  const onChangeUser = (value, label) => {
    setFormNewLogger((prev) => ({ ...prev, user_id: value }));
  };

  const handleOnChangeLogger = async (event) => {
    const key = event.target.name;

    setFormNewLogger((prev) => ({ ...prev, [key]: event.target.value }));
    //  setFormNewLogger({ ...formNewLogger, [key]: event.target.value });
  };

  //function for industry type
  const onChangeIndustryType = (value, label) => {
    setSelectedIndustryType(value);
  };

  const onSearchIndustryType = (value) => {};

  const filterOptionIndustryType = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  //

  useEffect(() => {
    return () => debouncedFetchAllLogger.cancel();
  }, [debouncedFetchAllLogger]);

  useEffect(() => {
    fetchAllIndustryTypes({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    fetchLogger({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    fetchDataCompany({});
  }, []);

  useEffect(() => {
    fetchAllUser({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    setDataUser(JSON.parse(localStorage.getItem(USER)));
  }, [localStorage[USER]]);

  return (
    <div>
      <p className="text-xl font-bold">Logger</p>

      <div className="flex mb-6 mt-2 justify-between">
        {["admin", "developer"].includes(dataUser?.role) && (
          <div className="flex gap-2">
            <Button type="primary" onClick={showModal}>
              Create new logger
            </Button>
          </div>
        )}

        <Search
          placeholder="Search logger name"
          onChange={onSearchInputChange}
          style={{
            width: 200,
          }}
        />
      </div>

      <Table
        dataSource={loggers}
        columns={columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Create New Logger"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="mb-2">You need to select company to create a logger</p>
        <Select
          showSearch
          placeholder="Select a company"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          className="w-full mb-4"
          options={(companies || []).map((company) => ({
            key: company._id,
            value: company._id,
            label: company.company_name,
          }))}
        />

        <p className="mb-2">Logger Name</p>
        <Input
          onChange={handleOnChangeLogger}
          name="logger_name"
          id="logger_name"
          placeholder="Logger name"
          className="mb-4"
        />

        <p className="mb-2">Logger Address</p>
        <Input
          onChange={handleOnChangeLogger}
          name="logger_address"
          id="logger_address"
          placeholder="Logger address"
          className="mb-4"
        />

        <p className="mb-2">Select User</p>
        <Select
          showSearch
          placeholder="Select a user (optional)"
          optionFilterProp="children"
          onChange={onChangeUser}
          onSearch={onSearchUser}
          filterOption={filterOptionUser}
          className="w-full mb-4"
          options={(users || []).map((user) => ({
            key: user._id,
            value: user._id,
            label: user.email,
          }))}
        />

        <div className="my-6">
          <label
            htmlFor="industry-type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Industry Type
          </label>
          <Select
            showSearch
            id="industry_type_id"
            name="industry_type_id"
            required
            placeholder="Industry Type"
            optionFilterProp="children"
            onChange={onChangeIndustryType}
            onSearch={onSearchIndustryType}
            filterOption={filterOptionIndustryType}
            className="w-full"
            options={(allIndustryTypes || []).map((data) => ({
              key: data._id,
              value: data._id,
              label: data.industry_type,
            }))}
          />
        </div>

        <p className="mb-1">Select file for logo of logger</p>
        <div className="">
          <img id="loggerImage" src="" alt="" />
        </div>
        <input
          className="border-2 border-slate-100 py-1 rounded-md"
          onChange={onImageUploadHandler}
          type="file"
          id="logger_image"
          name="file"
        />
        <div>
          {!isAccept ? (
            <div className=" text-red-600">
              *File must be in .jpeg or .png and size must not bigger than 1MB
            </div>
          ) : null}
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => onClickSubmitHandler()}
          >
            Create
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Logger;
