import axios from "axios"
import { create } from "zustand"
import { AUTH_TOKEN } from "../helpers/constant"


export const useErrorLogsStore = create((set, get) => ({
    errorLogs: [],
    fetchAllErrorLogs: async ({ startDate, endDate }) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/error-logs`, { params: { startDate, endDate }, headers: { authorization: `Bearer ${token}` } })

        set((state) => ({
            data: (state.errorLogs = response.data.data)
        }))
    },
}))