import { Button, Table, Modal, Input, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Formik, Field, Form } from "formik";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import { LineOutlined } from "@ant-design/icons";
import { useThresholdsStore } from "../store/thresholdsStore";
import { useSensorStore } from "../store/sensorStore";
import { useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";

function IndustryType() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        allIndustryTypes,
        fetchAllIndustryTypes,
        createIndustryType,
        deleteIndustryType,
        editIndustryType,
        fetchIndustryTypeById,
    } = useIndustryTypeStore();
    const { sensors, fetchAllSensor } = useSensorStore();
    const { editThresholdsByIdThreshold, fetchThresholdsById } =
        useThresholdsStore();
    const [formEditIndustry, setFormEditIndustry] = useState({
        industry_type: "",
        maxSuhu: "",
        // maxDhl: "",
        maxTds: "",
        maxDo: "",
        minpH: "",
        maxpH: "",
        maxTurbidity: "",
        maxKedalaman: "",
        maxNitrat: "",
        maxAmonia: "",
        maxCod: "",
        maxBod: "",
        maxTss: "",
        // maxSwsg : "",
        // maxSalinitas : "",
        // maxOrp : ""
        sensors: [],
    });
    const [formAddNewSensor, setFormAddNewSensor] = useState({ data_sensor: [] })
    const [idIndustryType, setIdIndustryType] = useState("");
    const [idThreshold, setIdThreshold] = useState("");
    const { Search } = Input;
    const searchItems = searchParams.get("searchItems");

    // console.log({ allIndustryTypes });

    const debouncedFetchAllIndustryType = _debounce((value) => {
        fetchAllIndustryTypes({ searchItems: value });
    }, 1000);

    const onSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchParams((params) => {
            params.set("searchItems", value);
            return params;
        });
        debouncedFetchAllIndustryType(value);
    };

    const sensorOptions = sensors.map((item) => item.sensor_name);

    const columns = [
        {
            title: "Industry Type",
            dataIndex: "industry_type",
            key: "industry_type",
            render: (value, record) => `${record?.industry_type || "-"}`,
        },
        {
            title: "Sensor",
            dataIndex: "sensors",
            key: "sensors",
            render: (value, record) =>
                record.sensors.map((d) => d.sensor_name || "-").join(", "),
        },

        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">Suhu</p>
                        <sub>
                            (°C)
                        </sub>
                    </div>
                );
            },
            dataIndex: "suhu",
            key: "suhu",
            render: (value, record) => `${record?.id_threshold?.maxSuhu || "-"}`,
        },
        // {
        //     title: () => {
        //         return (
        //             <div className="">
        //                 <p className="m-0">Daya Hantar Listrik</p>
        //                 <sub>
        //                     (μS/cm)
        //                 </sub>
        //             </div>
        //         );
        //     },
        //     dataIndex: "dhl",
        //     key: "dhl",
        //     render: (value, record) => `${record?.id_threshold?.maxDhl || "-"}`,
        // },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">TDS</p>
                        <sub>
                            (mg/L)
                        </sub>
                    </div>
                );
            },
            dataIndex: "tds",
            key: "tds",
            render: (value, record) => `${record?.id_threshold?.maxTds || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">DO</p>
                        <sub>
                            (mg/L)
                        </sub>
                    </div>
                );
            },
            dataIndex: "do",
            key: "do",
            render: (value, record) => `${record?.id_threshold?.maxDo || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">pH</p>
                    </div>
                );
            },
            dataIndex: "ph",
            key: "ph",
            render: (value, record) =>
                <div className="flex gap-1">
                    {record?.id_threshold?.minpH || ""}
                    <LineOutlined />
                    {record?.id_threshold?.maxpH || ""}
                </div>
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">Turbidity</p>
                        <sub>
                            (NTU)
                        </sub>
                    </div>
                );
            },
            dataIndex: "turbidity",
            key: "turbidity",
            render: (value, record) => `${record?.id_threshold?.maxTurbidity || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">Kedalaman</p>
                        <sub>
                            (m)
                        </sub>
                    </div>
                );
            },
            dataIndex: "kedalaman",
            key: "kedalaman",
            render: (value, record) => `${record?.id_threshold?.maxKedalaman || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">Nitrat</p>
                        <sub>
                            (mg/L)
                        </sub>
                    </div>
                );
            },
            dataIndex: "nitrat",
            key: "nitrat",
            render: (value, record) => `${record?.id_threshold?.maxNitrat || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">Amonia</p>
                        <sub>
                            (mg/L)
                        </sub>
                    </div>
                );
            },
            dataIndex: "amonia",
            key: "amonia",
            render: (value, record) => `${record?.id_threshold?.maxAmonia || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">COD</p>
                        <sub>(mg/L)</sub>
                    </div>
                );
            },
            dataIndex: "cod",
            key: "cod",
            render: (value, record) => `${record?.id_threshold?.maxCod || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">BOD</p>
                        <sub>(mg/L)</sub>
                    </div>
                );
            },
            dataIndex: "bod",
            key: "bod",
            render: (value, record) => `${record?.id_threshold?.maxBod || "-"}`
        },
        {
            title: () => {
                return (
                    <div className="">
                        <p className="m-0">TSS</p>
                        <sub>(mg/L)</sub>
                    </div>
                );
            },
            dataIndex: "tss",
            key: "tss",
            render: (value, record) => `${record?.id_threshold?.maxTss || "-"}`
        },

        // {
        //     title: () => {
        //         return (
        //             <div className="">
        //                 <p className="m-0">SwSG</p>
        //                 <sub>
        //                     (mg/L)
        //                 </sub>
        //             </div>
        //         );
        //     },
        //     dataIndex: "swsg",
        //     key: "swsg",
        //     render: (value, record) =>`${record?.id_threshold?.maxSwsg || "-"}`
        // },
        // {
        //     title: () => {
        //         return (
        //             <div className="">
        //                 <p className="m-0">Salinitas</p>
        //                 <sub>
        //                     (mg/L)
        //                 </sub>
        //             </div>
        //         );
        //     },
        //     dataIndex: "salinitas",
        //     key: "salinitas",
        //     render: (value, record) =>`${record?.id_threshold?.maxSalinitas || "-"}`
        // },
        // {
        //     title: () => {
        //         return (
        //             <div className="">
        //                 <p className="m-0">ORP</p>
        //                 <sub>
        //                     (mg/L)
        //                 </sub>
        //             </div>
        //         );
        //     },
        //     dataIndex: "orp",
        //     key: "orp",
        //     render: (value, record) =>`${record?.id_threshold?.maxOrp || "-"}`
        // },

        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <div className="flex gap-2 items-center">
                    <Button
                        onClick={() => handleEditIndustryType(record)}
                        className="border-blue-500 border-1 text-blue-500 "
                    >
                        Edit
                    </Button>

                    <Button danger onClick={() => handleConfirmDelete(record._id)}>
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const handleEditIndustryType = (record) => {
        setIdIndustryType(record._id);
        setIdThreshold(record?.id_threshold?._id);
        setFormEditIndustry({
            industry_type: record?.industry_type || "-",
            maxSuhu: record?.id_threshold?.maxSuhu || "-",
            // maxDhl: record?.id_threshold?.maxDhl || "-",
            maxTds: record?.id_threshold?.maxTds || "-",
            maxDo: record?.id_threshold?.maxDo || "-",
            minpH: record?.id_threshold?.minpH || "-",
            maxpH: record?.id_threshold?.maxpH || "-",
            maxTurbidity: record?.id_threshold?.maxTurbidity || "-",
            maxKedalaman: record?.id_threshold?.maxKedalaman || "-",
            maxNitrat: record?.id_threshold?.maxNitrat || "-",
            maxAmonia: record?.id_threshold?.maxAmonia || "-",
            maxCod: record?.id_threshold?.maxCod || "-",
            maxBod: record?.id_threshold?.maxBod || "-",
            maxTss: record?.id_threshold?.maxTss || "-",
            // maxSwsg: record?.id_threshold?.maxSwsg || "-",
            // maxSalinitas: record?.id_threshold?.maxSalinitas || "-",
            // maxOrp: record?.id_threshold?.maxOrp || "-",
            sensors: (record?.sensors || []).map((sensor) => sensor._id),
        });
        setIsEditModalOpen(true);
    };

    const handleEditIndustryForm = async (event) => {
        const key = event.target.name;
        setFormEditIndustry({ ...formEditIndustry, [key]: event.target.value });
    };

    const handleConfirmationEditIndustry = async () => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You want to edit this industry type?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Edit Industry Type",
        });
        if (result.isConfirmed) {
            onClickSubmitEditHandler();
        }
    };

    const onClickSubmitEditHandler = async () => {
        try {
            setIsLoading(true);
            editIndustryType(idIndustryType, formEditIndustry);
            editThresholdsByIdThreshold(idThreshold, formEditIndustry);
            setIsLoading(false);
            setIsEditModalOpen(false);

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Success edit industry type",
            });


            setFormEditIndustry({
                industry_type: "",
                maxSuhu: "",
                // maxDhl: "",
                maxTds: "",
                maxDo: "",
                minpH: "",
                maxpH: "",
                maxTurbidity: "",
                maxKedalaman: "",
                maxNitrat: "",
                maxAmonia: "",
                maxCod: "",
                maxBod: "",
                maxTss: "",
                // maxSwsg : "",
                // maxSalinitas : "",
                // maxOrp : ""
                sensors: [],
            });
        } catch (error) {
            setIsLoading(false);
            setIsEditModalOpen(false);
        }
    };

    //function for modal create industry type
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCreateIndustryType = async (value, actions) => {
        console.log(value)
        if (value) {
            const d = {
                industry_type: value.industry_type || "",
                maxSuhu: value.maxSuhu || "-",
                // maxDhl: value.maxDhl || "-",
                maxTds: value.maxTds || "-",
                maxDo: value.maxDo || "-",
                minpH: value.minpH || "-",
                maxpH: value.maxpH || "-",
                maxTurbidity: value.maxTurbidity || "-",
                maxKedalaman: value.maxKedalaman || "-",
                maxNitrat: value.maxNitrat || "-",
                maxAmonia: value.maxAmonia || "-",
                maxCod: value.maxCod || "-",
                maxBod: value.maxBod || "-",
                maxTss: value.maxTss || "-",
                // maxSwsg: value.maxSwsg || "-",
                // maxSalinitas: value.maxSalinitas || "-",
                // maxOrp: value.maxOrp || "-",
                data_sensor: formAddNewSensor.data_sensor || [],
            };

            createIndustryType(d);
            setIsModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    //

    const handleCancelEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleDeleteIndustryType = async (id) => {
        try {
            deleteIndustryType(id);
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Success delete industry type",
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
        }
    };

    const handleConfirmDelete = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this industry type",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        });
        if (result.isConfirmed) {
            handleDeleteIndustryType(id);
        }
    };

    // function for checkbox sensor
    const onChange = (checkedValue) => {
        // console.log(checkedValue)
        setFormAddNewSensor({ ...formAddNewSensor, data_sensor: checkedValue });

    };
    //

    // function for checkbox sensor (edit)
    const onChangeEditSensor = (checkedValues) => {
        setFormEditIndustry({ ...formEditIndustry, sensors: checkedValues });
    };
    //

    useEffect(() => {
        return () => debouncedFetchAllIndustryType.cancel();
    }, [debouncedFetchAllIndustryType]);

    useEffect(() => {
        fetchAllIndustryTypes({ searchItems });
    }, [searchItems]);

    useEffect(() => {
        if (idIndustryType) {
            fetchIndustryTypeById(idIndustryType);
        }
    }, [idIndustryType]);

    useEffect(() => {
        if (idThreshold) {
            fetchThresholdsById(idThreshold);
        }
    }, [idThreshold]);

    useEffect(() => {
        fetchAllSensor({ searchItems });
    }, [searchItems]);

    return (
        <div>
            <p className="text-xl font-bold">Industry List</p>
            <div className="flex mb-6 mt-2 justify-between">
                <div className="flex gap-2">
                    <Button type="primary" onClick={showModal}>
                        Create new industry
                    </Button>
                </div>

                <Search
                    placeholder="Search industry type"
                    onChange={onSearchInputChange}
                    style={{
                        width: 200,
                    }}
                />
            </div>

            <Table
                dataSource={allIndustryTypes}
                columns={columns}
                pagination={{ pageSize: 5 }}
                scroll={{ x: "max-content" }}
            />

            <Modal
                title="Create New Industry"
                open={isModalOpen}
                // onOk={handleOk}
                okText="Create"
                onCancel={handleCancel}
                footer={null}
            >
                <Formik
                    initialValues={{
                        industry_type: "",
                        maxSuhu: "",
                        // maxDhl: "",
                        maxTds: "",
                        maxDo: "",
                        minpH: "",
                        maxpH: "",
                        maxTurbidity: "",
                        maxKedalaman: "",
                        maxNitrat: "",
                        maxAmonia: "",
                        maxCod: "",
                        maxBod: "",
                        maxTss: "",
                        // maxSwsg : "",
                        // maxSalinitas : "",
                        // maxOrp : ""
                        // data_sensor:[]
                    }}
                    onSubmit={(value, actions) => {
                        handleCreateIndustryType(value, actions);
                    }}
                >
                    {(props) => {
                        return (
                            <div>
                                <Form className="mt-8 space-y-6" action="#" method="POST">
                                    <input type="hidden" name="remember" defaultValue="true" />
                                    <div className="my-6">
                                        <label
                                            htmlFor="industry_type"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Industry Type
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="industry_type"
                                                name="industry_type"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Industry Type"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="activeSensor"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Active Sensor
                                        </label>
                                        <Checkbox.Group
                                            // options={sensorOptions}
                                            onChange={onChange}
                                            options={(sensors || []).map((data) => ({
                                                key: data._id,
                                                value: data._id,
                                                label: data.sensor_name,
                                            }))}
                                        />
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxSuhu"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Suhu
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxSuhu"
                                                name="maxSuhu"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Suhu"
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="my-6">
                                        <label
                                            htmlFor="maxDhl"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Daya Hantar Listrik
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxDhl"
                                                name="maxDhl"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Daya Hantar Listrik"
                                            />
                                        </div>
                                    </div> */}

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxTds"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max TDS
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxTds"
                                                name="maxTds"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max TDS"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxDo"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max DO
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxDo"
                                                name="maxDo"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max DO"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6 flex gap-7 w-full">
                                        <div className="w-[44%]">
                                            <label
                                                htmlFor="minpH"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Min pH
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    id="minpH"
                                                    name="minpH"
                                                    type="text"
                                                    required
                                                    className="pl-4 shadow-sm relative block w-full h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                    placeholder="Min pH"
                                                />
                                            </div>
                                        </div>

                                        <div className="w-[44%]">
                                            <label
                                                htmlFor="maxpH"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Max pH
                                            </label>
                                            <div className="mt-2"></div>
                                            <Field
                                                id="maxpH"
                                                name="maxpH"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-full h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max pH"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxTurbidity"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Turbidity
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxTurbidity"
                                                name="maxTurbidity"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Turbidity"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxKedalaman"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Kedalaman
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxKedalaman"
                                                name="maxKedalaman"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Kedalaman"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxNitrat"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Nitrat
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxNitrat"
                                                name="maxNitrat"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Nitrat"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxAmonia"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Amonia
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxAmonia"
                                                name="maxAmonia"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Amonia"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxCod"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max COD
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxCod"
                                                name="maxCod"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max COD"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxBod"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max BOD
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxBod"
                                                name="maxBod"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max BOD"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-6">
                                        <label
                                            htmlFor="maxTss"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max TSS
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxTss"
                                                name="maxTss"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max TSS"
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="my-6">
                                        <label
                                            htmlFor="maxSwsg"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max SwSG
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxSwsg"
                                                name="maxSwsg"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max SwSG"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-6">
                                        <label
                                            htmlFor="maxSalinitas"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max Salinitas
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxSalinitas"
                                                name="maxSalinitas"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max Salinitas"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-6">
                                        <label
                                            htmlFor="maxOrp"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Max ORP
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                id="maxOrp"
                                                name="maxOrp"
                                                type="text"
                                                required
                                                className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                placeholder="Max ORP"
                                            />
                                        </div>
                                    </div> */}

                                    <div className="flex justify-end gap-2 mt-4">
                                        <Button key="back" onClick={handleCancel}>
                                            Cancel
                                        </Button>

                                        <Button
                                            key="submit"
                                            type="primary"
                                            htmlType="submit"
                                            loading={isLoading}
                                            onClick={() => {
                                                handleCreateIndustryType();
                                            }}
                                        >
                                            Create
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </Modal>

            <Modal
                title="Edit Industry Type"
                open={isEditModalOpen}
                okText="Edit"
                onCancel={handleCancelEditModal}
                footer={null}
            >
                <p className="mb-1">Industry Type</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="industry_type"
                    id="industry_type"
                    value={formEditIndustry.industry_type}
                />

                <p className="mb-1">Active Sensor</p>
                <Checkbox.Group
                    // options={sensorOptions}
                    onChange={onChangeEditSensor}
                    value={formEditIndustry.sensors}
                    options={(sensors || []).map((data) => ({
                        key: data._id,
                        value: data._id,
                        label: data.sensor_name,
                    }))}
                />

                <p className="mb-1">Max Suhu</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxSuhu"
                    id="maxSuhu"
                    value={formEditIndustry.maxSuhu}
                />

                {/* <p className="mb-1">Max Daya Hantar Listrik</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxDhl"
                    id="maxDhl"
                    value={formEditIndustry.maxDhl}
                /> */}

                <p className="mb-1">Max TDS</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxTds"
                    id="maxTds"
                    value={formEditIndustry.maxTds}
                />

                <p className="mb-1">Max DO</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxDo"
                    id="maxDo"
                    value={formEditIndustry.maxDo}
                />

                <div className="flex gap-5">
                    <div className="w-[48%]">
                        <p className="mb-1">Min pH</p>
                        <Input
                            onChange={handleEditIndustryForm}
                            name="minpH"
                            id="minpH"
                            value={formEditIndustry.minpH}
                        />
                    </div>

                    <div className="w-[48%]">
                        <p className="mb-1">Max pH</p>
                        <Input
                            onChange={handleEditIndustryForm}
                            name="maxpH"
                            id="maxpH"
                            value={formEditIndustry.maxpH}
                        />
                    </div>
                </div>

                <p className="mb-1">Max Turbidity</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxTurbidity"
                    id="maxTurbidity"
                    value={formEditIndustry.maxTurbidity}
                />

                <p className="mb-1">Max Kedalaman</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxKedalaman"
                    id="maxKedalaman"
                    value={formEditIndustry.maxKedalaman}
                />

                <p className="mb-1">Max Nitrat</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxNitrat"
                    id="maxNitrat"
                    value={formEditIndustry.maxNitrat}
                />

                <p className="mb-1">Max Amonia</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxAmonia"
                    id="maxAmonia"
                    value={formEditIndustry.maxAmonia}
                />

                <p className="mb-1">Max COD</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxCod"
                    id="maxCod"
                    value={formEditIndustry.maxCod}
                />

                <p className="mb-1">Max BOD</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxBod"
                    id="maxBod"
                    value={formEditIndustry.maxBod}
                />

                <p className="mb-1">Max TSS</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxTss"
                    id="maxTss"
                    value={formEditIndustry.maxTss}
                />

                {/* <p className="mb-1">Max SwSG</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxSwsg"
                    id="maxSwsg"
                    value={formEditIndustry.maxSwsg}
                />
                <p className="mb-1">Max Salinitas</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxSalinitas"
                    id="maxSalinitas"
                    value={formEditIndustry.maxSalinitas}
                />
                <p className="mb-1">Max ORP</p>
                <Input
                    onChange={handleEditIndustryForm}
                    name="maxOrp"
                    id="maxOrp"
                    value={formEditIndustry.maxOrp}
                /> */}

                <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancelEditModal}>
                        Cancel
                    </Button>

                    <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={() => {
                            handleConfirmationEditIndustry();
                        }}
                    >
                        Edit
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default IndustryType;
