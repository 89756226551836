import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/constant";

export const useLoggerDataStore = create((set, get) => ({
    allLoggerData: [],
    loggerDataByUID: [],
    loggerDataCharts: [],
    loggerDataPerMenit: [],
    loggerDataPerHour: [],
    loggerDataPerDay: [],
    fetchLoggerData: async () => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(`${process.env.REACT_APP_API_BE}/data`, {
                headers: { authorization: `Bearer ${token}` },
            });

            set((state) => ({
                data: (state.allLoggerData = response.data.data),
            }));
        } catch (error) { }
    },
    fetchLoggerDataByUid: async ({ idStasiunLogger, startDate, endDate }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/data/${idStasiunLogger}`,
                {
                    params: { startDate, endDate },
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerDataByUID = response.data.data),
            }));
        } catch (error) { }
    },
    fetchLoggerDataByUidForCharts: async ({ idStasiunLogger, startDate, endDate }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/data/c/${idStasiunLogger}`,
                {
                    params: { startDate, endDate },
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerDataCharts = response.data.data),
            }));
        } catch (error) { }
    },
    fetchDataPerMenit: async ({ idStasiunLogger, startDate, endDate, page }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/data/m/${idStasiunLogger}`,
                {
                    params: { startDate, endDate, page },
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerDataPerMenit = response.data.data),
            }));
        } catch (error) { }
    },
    fetchDataPerHour: async ({ idStasiunLogger, startDate, endDate, page }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/data/h/${idStasiunLogger}`,
                {
                    params: { startDate, endDate, page },
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerDataPerHour = response.data.data),
            }));
        } catch (error) { }
    },
    fetchDataPerDay: async ({ idStasiunLogger, startDate, endDate, page }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/data/d/${idStasiunLogger}`,
                {
                    params: { startDate, endDate, page },
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerDataPerDay = response.data.data),
            }));
        } catch (error) { }
    },
}));
