import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/constant";
import Swal from "sweetalert2";

export const useLoggerStore = create((set, get) => ({
    loggers: [],
    logger: {},
    loggersPerCompany: [],
    loggerPerUser: {},
    fetchLogger: async ({ searchItems }) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(`${process.env.REACT_APP_API_BE}/logger`, {
                params: {
                    searchItems,
                },
                headers: { authorization: `Bearer ${token}` },
            });

            set((state) => ({
                data: (state.loggers = response.data.data),
            }));
        } catch (error) { }
    },
    createLogger: async (d) => {
        const token = localStorage.getItem(AUTH_TOKEN);

        try {
            let response = await axios.post(
                `${process.env.REACT_APP_API_BE}/logger`,
                d,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            // window.location.href = `/logger/${response?.data?.data}`;
            await get().fetchLogger();
        } catch (error) { }
    },
    fetchLoggerById: async (id) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/logger/${id}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.logger = response.data.data[0]),
            }));
        } catch (error) { }
    },
    fetchLoggerByCompanyId: async (companyId) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/logger/c/${companyId}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggersPerCompany = response.data.data),
            }));
        } catch (error) { }
    },
    fetchLoggerByUserId: async () => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_BE}/logger/u/l`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            set((state) => ({
                data: (state.loggerPerUser = response.data.data),
            }));
        } catch (error) { }
    },
    deleteLogger: async (id) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.delete(
                `${process.env.REACT_APP_API_BE}/logger/${id}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            await get().fetchLogger();
        } catch (error) { }
    },
    editLogger: async (idLogger, data) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.put(
                `${process.env.REACT_APP_API_BE}/logger/${idLogger}`,
                data,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: response?.data?.message || "Success edit logger",
            });

            await get().fetchLoggerById(idLogger);
        } catch (error) { }
    },
    editLoggerInDetailCompany: async (idCompany, idLogger, data) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        try {
            let response = await axios.put(
                `${process.env.REACT_APP_API_BE}/logger/${idLogger}`,
                data,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            );

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: response?.data?.message || "Success edit logger",
            });

            await get().fetchLoggerByCompanyId(idCompany);
        } catch (error) { }
    },
}));
