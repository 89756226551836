import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../../store/loggerStore";
import { useCompanyStore } from "../../store/companyStore";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button } from "antd";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../../components/DropdownItems";
import LineChartApex from "../../components/LineChartsApex";
import DownloadPdf from "../../components/DownloadPDF";
import { useSensorStore } from "../../store/sensorStore";

function ChartsPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const { loggerDataCharts, fetchLoggerDataByUidForCharts } =
    useLoggerDataStore();
  const companyId = loggerPerUser[0]?.company_id;
  // const uidLogger = loggerPerUser[0]?.uid_logger;
  // const uidLogger = loggerPerUser[0]?.uid;
  const idStasiunLogger = loggerPerUser[0]?.idstasiun;
  const { RangePicker } = DatePicker;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const { CSVDownloader, Type } = useCSVDownloader();
  const loggerSensor = [];
  const [loading, setLoading] = useState(true);

  loggerDataCharts.sort((a, b) => a.datetime - b.datetime);
  const searchItems = searchParams.get("searchItems");

  // to check what sensors are active in a logger
  if (loggerPerUser[0]?.sensors?.length > 0) {
    const dataSensor = loggerPerUser[0]?.sensors;
    // console.log({ dataSensor1: dataSensor })

    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  } else if (loggerPerUser[0]?.sensors?.length === 0) {
    const dataSensor = loggerPerUser[0]?.industry_type[0]?.sensors;
    // console.log({dataSensor2: dataSensor})
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }

  const isSensorNameIncluded = (sensorName) => {
    if (loggerSensor.length > 0) {
      return loggerSensor[0].includes(sensorName);
    }
  };
  //

  const initialMinMax = {
    suhu: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // dhl: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tds: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    do: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    ph: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    turbidity: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    kedalaman: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    nitrat: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    amonia: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    cod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    bod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tss: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // swSG:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // salinitas:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // orp:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
  };

  if (loggerDataCharts.length > 0) {
    loggerDataCharts.forEach((item) => {
      Object.keys(initialMinMax).forEach((property) => {
        initialMinMax[property].min = Math.min(
          initialMinMax[property].min,
          item[property]
        );
        initialMinMax[property].max = Math.max(
          initialMinMax[property].max,
          item[property]
        );
      });
    });
  } else {
    // Set initialMinMax values to 0 when loggerDataCharts is empty
    Object.keys(initialMinMax).forEach((property) => {
      initialMinMax[property].min = 0;
      initialMinMax[property].max = 0;
    });
  }

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const downloadedDataLogger = loggerDataCharts.map((data) => {
    return {
      logger_name: loggerPerUser[0]?.logger_name,
      date: convertDate(data?.datetime),
      datetime: data?.datetime,
      suhu: data.suhu,
      // dhl: data.dhl,
      tds: data.tds,
      do: data.do,
      ph: data.ph,
      turbidity: data.turbidity,
      kedalaman: data.kedalaman,
      nitrat: data.nitrat,
      amonia: data.amonia,
      cod: data.cod,
      bod: data.bod,
      tss: data.tss,
      // swSG: data.swSG,
      // salinitas: data.salinitas,
      // orp: data.orp
    };
  });

  const downloadXLSX = () => {
    const xlsxData = downloadedDataLogger;
    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "report.xlsx");
  };

  const thresholdsData = [];
  if (loggerPerUser.length > 0 && loggerPerUser[0]?.thresholds_logger[0]?._id) {
    thresholdsData.push(loggerPerUser[0]?.thresholds_logger[0]);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
      const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  const downloadItems = [
    {
      key: "1",
      label: (
        <p className="m-0">
          <DownloadPdf data={downloadedDataLogger} />
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          <CSVDownloader
            type={Type}
            filename={"filename"}
            bom={true}
            config={{
              delimiter: ";",
            }}
            data={downloadedDataLogger}
            className="text-slate-800"
          >
            CSV
          </CSVDownloader>
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0" onClick={downloadXLSX}>
          XLSX
        </p>
      ),
    },
  ];

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (loggerPerUser) {
      fetchLoggerByUserId();
    }
  }, [loggerPerUser?._id]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    // if (loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser[0]?.uid) {
    if (loggerPerUser[0]?.idstasiun) {
      fetchLoggerDataByUidForCharts({ idStasiunLogger, startDate, endDate });
      setLoading(false);
    }
  }, [idStasiunLogger, startDate, endDate]);

  return (
    <>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center">
          {/* <Spin className="" size="large" /> */}
          <p className="text-blue-400 m-0 text-lg">Loading...</p>
        </div>
      ) : (
        <>
          {/* <p className="text-xl font-bold text-center m-0">
            {loggerPerUser[0]?.logger_name || "-"}
          </p>
          <p className="text-center text-slate-400 ">
            {loggerPerUser[0]?.logger_address || ""}
          </p> */}

          <div>
            <p className="font-semibold text-center text-lg m-0 mb-3  text-slate-500">
              Charts Logger
            </p>
            <div className="flex gap-2 flex-wrap justify-center">
              <RangePicker
                className="mb-4"
                onChange={onChange}
                format="DD/MM/YYYY HH:mm"
              />
              {/* <RangePicker className="mb-4" onChange={onChange}
                format="DD/MM/YYYY HH:mm" /> */}
              <Button onClick={onClear} type="primary">
                Reset Date
              </Button>

              <DropdownItems items={downloadItems} title="Download" />
            </div>
          </div>

          <div className="grid grid-cols-2">
            {isSensorNameIncluded("suhu") ? (
              <div className="">
                <LineChartApex
                  title="Suhu"
                  selectedKey="suhu"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxSuhu || []}
                  min={initialMinMax?.suhu?.min}
                  max={initialMinMax?.suhu?.max}
                />
              </div>
            ) : (
              ""
            )}

            {/* {isSensorNameIncluded("dhl") ? (
              <div className="">
                <LineChartApex
                  title="Daya Hantar Listrik"
                  selectedKey="dhl"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxDhl || []}
                  min={initialMinMax?.dhl?.min}
                  max={initialMinMax?.dhl?.max}
                />
              </div>
            ) : (
              ""
            )} */}

            {isSensorNameIncluded("tds") ? (
              <div className="">
                <LineChartApex
                  title="TDS"
                  selectedKey="tds"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxTds || []}
                  min={initialMinMax?.tds?.min}
                  max={initialMinMax?.tds?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("do") ? (
              <div className="">
                <LineChartApex
                  title="DO"
                  selectedKey="do"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxDo || []}
                  min={initialMinMax?.do?.min}
                  max={initialMinMax?.do?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("pH") ? (
              <div className="">
                <LineChartApex
                  title="pH"
                  selectedKey="ph"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxpH || []}
                  thresholdsValue2={thresholdsData[0]?.minpH || []}
                  min={initialMinMax?.ph?.min - 2}
                  max={initialMinMax?.ph?.max + 1}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("turbidity") ? (
              <div className="">
                <LineChartApex
                  title="Turbidity"
                  selectedKey="turbidity"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxTurbidity || []}
                  min={initialMinMax?.turbidity?.min}
                  max={initialMinMax?.turbidity?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("kedalaman") ? (
              <div className="">
                <LineChartApex
                  title="Kedalaman"
                  selectedKey="kedalaman"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxKedalaman || []}
                  min={initialMinMax?.kedalaman?.min}
                  max={initialMinMax?.kedalaman?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("nitrat") ? (
              <div className="">
                <LineChartApex
                  title="Nitrat"
                  selectedKey="nitrat"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxNitrat || []}
                  min={initialMinMax?.nitrat?.min}
                  max={initialMinMax?.nitrat?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("amonia") ? (
              <div className="">
                <LineChartApex
                  title="Amonia"
                  selectedKey="amonia"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxAmonia || []}
                  min={initialMinMax?.amonia?.min}
                  max={initialMinMax?.amonia?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("cod") ? (
              <div className="">
                <LineChartApex
                  title="COD"
                  selectedKey="cod"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxCod || []}
                  min={initialMinMax?.cod?.min}
                  max={initialMinMax?.cod?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("bod") ? (
              <div className="">
                <LineChartApex
                  title="BOD"
                  selectedKey="bod"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxBod || []}
                  min={initialMinMax?.bod?.min}
                  max={initialMinMax?.bod?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("tss") ? (
              <div className="">
                <LineChartApex
                  title="TSS"
                  selectedKey="tss"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxTss || []}
                  min={initialMinMax?.tss?.min}
                  max={initialMinMax?.tss?.max}
                />
              </div>
            ) : (
              ""
            )}

            {/* {isSensorNameIncluded("swsg") ? (
              <div className="bg-[#041527]  ">
                <LineChartApex
                  title="SwSG"
                  selectedKey="swsg"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxSwsg || []}
                  min={initialMinMax?.swsg?.min}
                  max={initialMinMax?.swsg?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("salinitas") ? (
              <div className="bg-[#041527]  ">
                <LineChartApex
                  title="Salinitas"
                  selectedKey="salinitas"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxSalinitas || []}
                  min={initialMinMax?.salinitas?.min}
                  max={initialMinMax?.salinitas?.max}
                />
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("orp") ? (
              <div className="bg-[#041527]  ">
                <LineChartApex
                  title="ORP"
                  selectedKey="orp"
                  label="datetime"
                  height={256}
                  chartData={loggerDataCharts}
                  thresholdsValue1={thresholdsData[0]?.maxOrp || []}
                  min={initialMinMax?.orp?.min}
                  max={initialMinMax?.orp?.max}
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        </>
      )}
    </>
  );
}

export default ChartsPage;
