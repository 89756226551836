import React from "react";
import moment from "moment";

function LatestDataComponent({ loggerDataByUID, company, logger }) {
  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const thresholdsData = [];
  if (logger?.thresholds_logger?._id) {
    thresholdsData.push(logger?.thresholds_logger);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  return (
    <div className="flex flex-wrap justify-center sm:justify-between gap-6">
      <div className="w-full rounded-md">
        <p className="text-center mt-6 text-[#041527] font-semibold">
          Latest Data from {convertDate(loggerDataByUID[0]?.datetime)}
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 sm:justify-between gap-6 text-center justify-center mt-4">
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Suhu
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.suhu <= thresholdsData[0]?.maxSuhu ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.suhu}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    °C
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.suhu}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    °C
                  </sub>
                </p>
              )}
            </p>
          </div>
          {/* <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Daya Hantar Listrik
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.dhl <= thresholdsData[0]?.maxDhl ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.dhl}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    μS/cm
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.dhl}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    μS/cm
                  </sub>
                </p>
              )}
            </p>
          </div> */}
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              TDS
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.tds <= thresholdsData[0]?.maxTds ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.tds}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.tds}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              DO
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.do <= thresholdsData[0]?.maxDo ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.do}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.do}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 shadow-charts py-2 rounded-md flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              pH
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.ph >= thresholdsData[0]?.minpH &&
                loggerDataByUID[0]?.ph <= thresholdsData[0]?.maxpH ? (
                <p className="text-black m-0">{loggerDataByUID[0]?.ph}</p>
              ) : (
                <p className="text-red-600 m-0">{loggerDataByUID[0]?.ph}</p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Turbidity
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.turbidity <= thresholdsData[0]?.maxTurbidity ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.turbidity}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    NTU
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.turbidity}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    NTU
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Kedalaman
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.kedalaman <= thresholdsData[0]?.maxKedalaman ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.kedalaman}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    m
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.kedalaman}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    m
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Nitrat
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.nitrat <= thresholdsData[0]?.maxNitrat ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.nitrat}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.nitrat}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Amonia
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.amonia <= thresholdsData[0]?.maxAmonia ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.amonia}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.amonia}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              COD
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.cod <= thresholdsData[0]?.maxCod ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.cod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.cod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              BOD
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.bod <= thresholdsData[0]?.maxBod ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.bod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.bod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              TSS
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.tss <= thresholdsData[0]?.maxTss ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.tss}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.tss}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default LatestDataComponent;
