import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  body: {
    padding: "28px",
    width: "100%",
    height: "100%",
    paddingTop: "128px",
  },
  companyName: {
    fontSize: "18px",
    marginBottom: "10px",
    // marginTop: "128px",
    paddingTop: "10px",
    textAlign: "center",
    borderTop: "1px solid black",
  },
  date: {
    fontSize: "10px",
    marginBottom: "10px",
    textAlign: "center",
  },
  companyDescription: {
    fontSize: "14px",
    marginBottom: "14px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableHeader: {
    backgroundColor: "black",
    color: "white",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    // width: "16.66%",
    width:"7.7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  page: {
    position: "absolute",
    bottom: "10px",
    left: "50%",
    // transform: "translateX(-50%)",
    fontSize: 10,
  },
});

const convertDate = (date) => {
  const numDate = Number(date);
  return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
};

const TableData = ({ dataLogger }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.body} wrap>
      <View style={styles.companyName}>
        <Text>{dataLogger[0].logger_name}</Text>
      </View>
      <View style={styles.date}>
        <Text>
          {convertDate(dataLogger[0].datetime) === "Invalid date" &&
          convertDate(dataLogger[dataLogger?.length - 1].datetime) ===
            "Invalid date"
            ? `${dataLogger[0].date}  — ${
                dataLogger[dataLogger?.length - 1].date
              }`
            : `${convertDate(dataLogger[0].datetime)} — ${convertDate(
                dataLogger[dataLogger?.length - 1].datetime
              )}`}
        </Text>
      </View>

      <View style={styles.table}>
        <View style={{ ...styles.tableRow, ...styles.tableHeader }} fixed>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Date</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Suhu</Text>
          </View>
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Daya Hantar Listrik</Text>
          </View> */}
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>TDS</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Do</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>pH</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Turbidity</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Kedalaman</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Nitrat</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Amonia</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>COD</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>BOD</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>TSS</Text>
          </View>
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>SwSG</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Salinitas</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>ORP</Text>
          </View> */}
        </View>

        {dataLogger.map((data, i) => (
          <View style={styles.tableRow} key={i}>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>
                {moment.unix(data.datetime).format("DD-MM-YYYY, HH:mm") ===
                "Invalid date"
                  ? data?.date
                  : moment.unix(data.datetime).format("DD-MM-YYYY, HH:mm")}
              </Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.suhu}</Text>
            </View>
            {/* <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.dhl}</Text>
            </View> */}
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.tds}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.do}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.ph}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.turbidity}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.kedalaman}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.nitrat}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.amonia}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.cod}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.bod}</Text>
            </View>
            <View style={styles.tableCol} break>
              <Text style={styles.tableCell}>{data.tss}</Text>
            </View>
          </View>
        ))}
      </View>
      <Text
        style={styles.page}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} / ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

function DownloadPdf({ data }) {
  const lastIdx = data.length - 1;

  const isLastDataDatetimeValid = moment(data[lastIdx]?.date).format(
    "DD/MM/YYYY"
  );

  if (isLastDataDatetimeValid === "01/01/1970") {
    data.splice(lastIdx, 1);
  }

  return (
    <>
      <PDFDownloadLink
        document={<TableData dataLogger={data} />}
        fileName={`data-${moment().format("DD-MM-YYYY")}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <p className="m-0 text-slate-800">PDF</p>
          )
        }
      </PDFDownloadLink>
    </>
  );
}

export default DownloadPdf;
