import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button, Table } from "antd";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../../components/DropdownItems";
import DownloadPdf from "../../components/DownloadPDF";

function DataPerMenitComponent({ company, logger }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerDataPerMenit, fetchDataPerMenit } = useLoggerDataStore();
  // const uidLogger = logger?.uid_logger;
  // const uidLogger = logger?.uid;
  const idStasiunLogger = logger?.idstasiun;
  const { RangePicker } = DatePicker;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const { CSVDownloader, Type } = useCSVDownloader();
  const [loading, setLoading] = useState(false);

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const downloadedDataLogger = loggerDataPerMenit.map((data) => {
    return {
      logger_name: logger?.logger_name,
      date: convertDate(data.datetime),
      datetime: data.datetime,
      suhu: data.suhu,
      // dhl: data.dhl,
      tds: data.tds,
      do: data.do,
      ph: data.ph,
      turbidity: data.turbidity,
      kedalaman: data.kedalaman,
      nitrat: data.nitrat,
      amonia: data.amonia,
      cod: data.cod,
      bod: data.bod,
      tss: data.tss,
      // swSG: data.swSG,
      // salinitas: data.salinitas,
      // orp: data.orp
    };
  });

  const downloadXLSX = () => {
    const xlsxData = downloadedDataLogger;

    // const headers = ["Name", "Age", "City"];

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "report.xlsx");
  };

  const thresholdsData = [];
  if (company.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const columns = [
    {
      title: "Receive Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) => convertDate(record.datetime),
    },

    {
      title: () => {
        return (
          <div>
            <p className="m-0">Suhu</p>
            <sub>(°C)</sub>
          </div>
        );
      },
      dataIndex: "suhu",
      key: "suhu",

      render: (value, record) => (record?.suhu ? record.suhu : "0"),
    },
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">Daya Hantar listrik</p>
    //         <sub>(μS/cm)</sub>
    //       </div>
    //     );
    //   },
    //   dataIndex: "dhl",
    //   key: "dhl",
    //   render: (value, record) => value || "0",
    // },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">TDS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tds",
      key: "tds",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">DO</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "do",
      key: "do",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "ph",
      key: "ph",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">Turbidity</p>
            <sub>(NTU)</sub>
          </div>
        );
      },
      dataIndex: "turbidity",
      key: "turbidity",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">Kedalaman</p>
            <sub>(m)</sub>
          </div>
        );
      },
      dataIndex: "kedalaman",
      key: "kedalaman",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">Nitrat</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "nitrat",
      key: "nitrat",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">Amonia</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "amonia",
      key: "amonia",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "cod",
      key: "cod",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">BOD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "bod",
      key: "bod",
      render: (value, record) => value || "0",
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) => value || "0",
    },
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">SwSG</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "swsg",
    //   key: "swsg",
    //   render: (value, record) => value || "0",
    // },
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">Salinitas</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "salinitas",
    //   key: "salinitas",
    //   render: (value, record) => value || "0",
    // },
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         <p className="m-0">ORP</p>
    //         {/* <sub>(mg/L)</sub> */}
    //       </div>
    //     );
    //   },
    //   dataIndex: "orp",
    //   key: "orp",
    //   render: (value, record) => value || "0",
    // },
  ];

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
      const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  const downloadItems = [
    {
      key: "1",
      label: (
        <p className="m-0">
          <DownloadPdf data={downloadedDataLogger} />
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          <CSVDownloader
            type={Type}
            filename={"filename"}
            bom={true}
            config={{
              delimiter: ";",
            }}
            data={downloadedDataLogger}
            className="text-slate-800"
          >
            CSV
          </CSVDownloader>
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0" onClick={downloadXLSX}>
          XLSX
        </p>
      ),
    },
  ];

  useEffect(() => {
    // if (logger?.uid_logger) {
    // if (logger?.uid) {
    if (logger?.idstasiun) {
      fetchDataPerMenit({ idStasiunLogger, startDate, endDate });
    }
  }, [idStasiunLogger, startDate, endDate]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div>
            <div className="flex flex-wrap gap-2 mb-4">
              <RangePicker
                className=""
                onChange={onChange}
                allowClear={true}
                showTime={{
                  format: "HH:mm",
                }}
                format="DD/MM/YYYY HH:mm"
              />
              <Button onClick={onClear} type="primary">
                Reset Date
              </Button>

              <DropdownItems items={downloadItems} title="Download" />
            </div>
          </div>

          <div className="mt-1">
            <Table
              dataSource={loggerDataPerMenit}
              columns={columns}
              pagination={{ pageSize: 5 }}
              className="text-5xl"
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DataPerMenitComponent;
