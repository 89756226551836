import axios from "axios"
import { create } from "zustand"
import { AUTH_TOKEN } from "../helpers/constant"

export const useCityStore = create((set, get) => ({
    cities: [],
    fetchCities: async () => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/city`, { headers: { authorization: `Bearer ${token}` } })

        set((state) => ({
            data: state.cities = response.data.data
        }))
    }
}))