import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../store/loggerStore";
import { useCompanyStore } from "../store/companyStore";
import { useLoggerDataStore } from "../store/loggerDataStore";
import LatestDataComponent from "../components/nonUser/LatestDataComponent";
import { Button, Input, Modal, Select, Tabs, Tooltip } from "antd";
import ChartsComponent from "../components/nonUser/ChartsComponent";
import DataPerMenitComponent from "../components/nonUser/DataPerMenitComponent";
// import DataPerJamComponent from "../components/nonUser/DataPerJamComponent";
import DataPerHariComponent from "../components/nonUser/DataPerHariComponent";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import { useUserStore } from "../store/userStore";

function DetailLoggerNonUser() {
    const { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const { logger, fetchLoggerById, editLogger } = useLoggerStore();
    const { company, companies, fetchCompanyById, fetchDataCompany } =
        useCompanyStore();
    const { loggerDataByUID, fetchLoggerDataByUid } = useLoggerDataStore();
    const { allIndustryTypes, fetchAllIndustryTypes } = useIndustryTypeStore();
    const { users, fetchAllUser } = useUserStore();
    const companyId = logger?.company_id;
    // const uidLogger = logger?.uid_logger;
    // const uidLogger = logger?.uid;
    const idStasiunLogger = logger?.idstasiun;
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useState("small");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formEditLogger, setFormEditLogger] = useState({
        company_id: "",
        user_id: "",
        logger_name: "",
        logger_address: "",
        industry_type_id: "",
    });
    const [imagePreview, setImagePreview] = useState("");
    const [file, setFile] = useState();
    const [isAccept, setIsAccept] = useState(false);
    const searchItems = searchParams.get("searchItems");

    const onChangeTabs = (key) => { };

    const items = [
        {
            key: "1",
            label: "Latest Data",
            children: (
                <LatestDataComponent
                    loggerDataByUID={loggerDataByUID}
                    company={company}
                    logger={logger}
                />
            ),
        },
        {
            key: "2",
            label: "Charts",
            children: (
                <ChartsComponent
                    loggerDataByUID={loggerDataByUID}
                    company={company}
                    logger={logger}
                    idStasiunLogger={idStasiunLogger}
                />
            ),
        },
        {
            key: "3",
            label: "Data",
            children: <DataPerMenitComponent company={company} logger={logger} />,
        },
        // {
        //     key: "4",
        //     label: "Data Per Jam",
        //     children: <DataPerJamComponent company={company} logger={logger} />,
        // },
        {
            key: "5",
            label: "Data Harian",
            children: <DataPerHariComponent company={company} logger={logger} />,
        },
    ];

    //function for edit logger modal
    const showModal = () => {
        setFormEditLogger((prev) => ({
            ...prev,
            industry_type_id: logger.industry_type[0]?._id,
            user_id: logger.user_id,
            company_id: logger.company_id,
        }));
        setIsModalOpen(true);
    };
    // const handleOk = async (data) => {
    //   setIsModalOpen(false);
    // };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // const handleEditCompanyForm = async (event) => {
    //   const key = event.target.name;

    //   setFormEditLogger({ ...formEditLogger, [key]: event.target.value });
    // };

    const onImageUploadHandler = (e) => {
        const fileDetail = e.target.files[0];
        const fileFormat = fileDetail.type.split("/")[1];

        if (fileDetail.size > 1024 * 1024) {
            setIsAccept(false);
        } else if (
            fileFormat === "jpeg" ||
            fileFormat === "png" ||
            fileFormat === "jpg"
        ) {
            setIsAccept(true);
            setFile(fileDetail);
            let preview = document.getElementById("loggerImage");
            preview.src = URL.createObjectURL(e.target.files[0]);
        } else {
            setIsAccept(false);
        }

        setImagePreview(URL.createObjectURL(e.target.files[0]));
        URL.revokeObjectURL(imagePreview);
    };

    const handleConfirmationEditLogger = async () => {
        // setIsModalOpen(false);
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You want to edit this logger?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Edit Logger",
        });
        if (result.isConfirmed) {
            onClickSubmitEditHandler();
        }
    };

    const onClickSubmitEditHandler = async () => {
        try {
            setIsLoading(true);

            const formData = new FormData();
            if (file) {
                formData.append("file", file);
                formData.append("company_id", formEditLogger.company_id);
                formData.append(
                    "industry_type_id",
                    formEditLogger.industry_type_id || ""
                );
                formData.append("user_id", formEditLogger.user_id);
                formData.append("logger_name", formEditLogger.logger_name);
                formData.append("logger_address", formEditLogger.logger_address);
            }

            editLogger(id, formData);
            setIsLoading(false);
            setIsModalOpen(false);

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Success edit logger",
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!!",
            });
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };

    //function for select company
    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const onSearch = (value) => { };
    const onChange = (value, label) => {
        setFormEditLogger((prev) => ({ ...prev, company_id: value }));
    };

    //

    //function for select industry type
    const onChangeIndustryType = (value, label) => {
        // setSelectedIndustryType(value);
        setFormEditLogger((prev) => ({
            ...prev,
            industry_type_id: value,
        }));
    };

    const onSearchIndustryType = (value) => { };

    const filterOptionIndustryType = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    //

    //function for select user for a logger
    const filterOptionUser = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const onSearchUser = (value) => { };
    const onChangeUser = (value, label) => {
        setFormEditLogger((prev) => ({ ...prev, user_id: value }));
    };

    const handleOnChangeLogger = async (event) => {
        const key = event.target.name;

        setFormEditLogger((prev) => ({ ...prev, [key]: event.target.value }));
        //  setFormNewLogger({ ...formNewLogger, [key]: event.target.value });
    };

    useEffect(() => {
        fetchAllUser({ searchItems });
    }, [searchItems]);

    useEffect(() => {
        fetchAllIndustryTypes({ searchItems });
    }, [searchItems]);

    useEffect(() => {
        if (logger) {
            fetchLoggerById(id);
        }
    }, [logger._id]);

    useEffect(() => {
        if (companyId) {
            fetchCompanyById(companyId);
        }
    }, [companyId]);

    useEffect(() => {
        fetchDataCompany({ searchItems });
    }, [searchItems]);

    useEffect(() => {
        if (logger._id) {
            fetchLoggerDataByUid({ idStasiunLogger, startDate, endDate });
        }
    }, [idStasiunLogger, startDate, endDate]);

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <Link to={"/logger"}>
                        <ArrowLeftOutlined />
                    </Link>
                    <div className="flex items-start justify-center gap-4">
                        <div>
                            <p className="text-xl font-bold text-center m-0">
                                {logger?.logger_name || "-"}
                            </p>
                            <p className="text-center text-slate-400 ">
                                {logger?.logger_address || ""}
                            </p>
                        </div>
                        <Tooltip title="Edit Logger">
                            <EditOutlined
                                className="mt-1 cursor-pointer hover:text-blue-600"
                                onClick={showModal}
                            />
                        </Tooltip>
                    </div>

                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={size}
                        items={items}
                        onChange={onChangeTabs}
                    />
                </>
            )}

            <Modal
                title="Edit Logger"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <p className="mb-2">You need to select company to create a logger</p>
                <Select
                    showSearch
                    placeholder="Select a company"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    className="w-full mb-4"
                    value={formEditLogger?.company_id}
                    options={(companies || []).map((company) => ({
                        key: company._id,
                        value: company._id,
                        label: company.company_name,
                    }))}
                />

                <p className="mb-2">Logger Name</p>
                <Input
                    onChange={handleOnChangeLogger}
                    defaultValue={logger.logger_name ? logger.logger_name : "-"}
                    name="logger_name"
                    id="logger_name"
                    placeholder="Logger name"
                    className="mb-4"
                />

                <p className="mb-2">Logger Address</p>
                <Input
                    onChange={handleOnChangeLogger}
                    defaultValue={logger.logger_address ? logger.logger_address : "-"}
                    name="logger_address"
                    id="logger_address"
                    placeholder="Logger address"
                    className="mb-4"
                />

                <p className="mb-2">Select User</p>
                <Select
                    showSearch
                    placeholder="Select a user (optional)"
                    optionFilterProp="children"
                    onChange={onChangeUser}
                    onSearch={onSearchUser}
                    filterOption={filterOptionUser}
                    value={formEditLogger?.user_id}
                    className="w-full mb-4"
                    options={(users || []).map((user) => ({
                        key: user._id,
                        value: user._id,
                        label: user.email,
                    }))}
                />

                <div className="my-6">
                    <label
                        htmlFor="industry-type"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Industry Type
                    </label>
                    <Select
                        showSearch
                        id="industry_type_id"
                        name="industry_type_id"
                        required
                        placeholder="Industry Type"
                        optionFilterProp="children"
                        onChange={onChangeIndustryType}
                        onSearch={onSearchIndustryType}
                        filterOption={filterOptionIndustryType}
                        value={formEditLogger?.industry_type_id}
                        className="w-full"
                        options={(allIndustryTypes || []).map((data) => ({
                            key: data._id,
                            value: data._id,
                            label: data.industry_type,
                        }))}
                    />
                </div>

                <p className="mb-1">Select file for logo of logger</p>
                <div className="">
                    <img id="loggerImage" src="" alt="" />
                </div>
                <input
                    className="border-2 border-slate-100 py-1 rounded-md"
                    onChange={onImageUploadHandler}
                    type="file"
                    id="logger_image"
                    name="file"
                // defaultValue={logger.logger_image ? logger.logger_image : "-"}
                />
                <div>
                    {!isAccept ? (
                        <div className=" text-red-600">
                            *File must be in .jpeg or .png and size must not bigger than 1MB
                        </div>
                    ) : null}
                </div>

                <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={() => handleConfirmationEditLogger()}
                    >
                        Edit Logger
                    </Button>
                </div>
            </Modal>
        </>
    );
}

export default DetailLoggerNonUser;
