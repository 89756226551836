import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useEffect } from "react";
import {
  UserOutlined,
  DatabaseOutlined,
  AreaChartOutlined,
  ApartmentOutlined,
  DiffOutlined,
  UsergroupAddOutlined,
  ClockCircleOutlined,
  TableOutlined,
  LineChartOutlined,
  GroupOutlined,
  HddOutlined,
  WarningOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { USER } from "../helpers/constant";
import { useLoggerStore } from "../store/loggerStore";

const sidebarItems = [
  // {
  //     key: "/",
  //     icon: <AreaChartOutlined />,
  //     label: "Dashboard",
  //     roles: ["developer"]
  // },
  {
    key: "/manage-sensor",
    icon: <ClockCircleOutlined />,
    label: "Sensor",
    roles: ["developer"],
  },
  {
    key: "/manage-user",
    icon: <UsergroupAddOutlined />,
    label: "User",
    roles: ["developer"],
  },
  {
    key: "/industry-type",
    icon: <DiffOutlined />,
    label: "Industry Type",
    roles: ["admin", "developer"],
  },
  {
    key: "/company",
    icon: <ApartmentOutlined />,
    label: "Company",
    roles: ["admin", "developer", "internal_bbt"],
  },
  {
    key: "/logger",
    icon: <DatabaseOutlined />,
    label: "Logger",
    roles: ["admin", "developer", "internal_bbt"],
  },
  {
    key: "/company/logger",
    icon: <DatabaseOutlined />,
    label: "Company Details",
    roles: ["superuser"],
  },
  {
    key: "/user/dashboard-user",
    icon: <AreaChartOutlined />,
    label: "Dashboard",
    roles: ["user"],
  },
  {
    key: "/user/logger-charts",
    icon: <LineChartOutlined />,
    label: "Charts",
    roles: ["user"],
  },
  {
    key: "/user/data-per-menit",
    icon: <TableOutlined />,
    label: "Data",
    roles: ["user"],
  },
  // {
  //     key: "/user/data-per-jam",
  //     icon: <GroupOutlined />,
  //     label: "Data Per Jam",
  //     roles: ["user"],
  // },
  {
    key: "/user/data-per-hari",
    icon: <HddOutlined />,
    label: "Data Harian",
    roles: ["user"],
  },
  //   {
  //     key: "/error-logs",
  //     icon: <WarningOutlined />,
  //     label: "Error Logs",
  //     roles: ["developer"],
  //   },
  //   {
  //     key: "/daily-report",
  //     icon: <ExceptionOutlined />,
  //     label: "Daily Report",
  //     roles: ["developer"],
  //   },
  {
    key: "/profile",
    icon: <UserOutlined />,
    label: "Profile",
    roles: ["superuser", "user", "admin", "developer", "internal_bbt"],
  },
];

const urlForUser = [
  "/user/dashboard-user",
  "/user/logger-charts",
  "/user/data-per-menit",
  // "/user/data-per-jam",
  "/user/data-per-hari",
  "/profile",
];

function Sidebar({ collapsed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem(USER));
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();

  const handleLinks = (key) => {
    navigate(key);
  };

  useEffect(() => {
    if (dataUser?.userId) {
      fetchLoggerByUserId(dataUser?.userId);
    }
  }, []);

  const isUrlForUser = urlForUser.includes(location.pathname);
  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: "#CAE8FE", color: "black" }}
      >
        <div className="flex flex-col gap-11 w-full h-screen overflow-scroll hide-scrollbar">
          <div className="h-11 mx-auto mt-11 w-fit">
            {isUrlForUser ? (
              loggerPerUser[0]?.logger_image ? (
                <img
                  src={`${process.env.REACT_APP_API_BE}/uploads/${loggerPerUser[0]?.logger_image}`}
                  alt="logo logger"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: collapsed ? "70px" : "240px",
                    maxHeight: collapsed ? "50px" : "80px",
                    margin: collapsed ? "0 auto" : "",
                  }}
                />
              ) : (
                <p className="bg-white m-0"></p>
              )
            ) : (
              <p className="bg-white m-0"></p>
            )}
          </div>
          <Menu
            onClick={({ key }) => handleLinks(key)}
            className="h-2/3 overflow-scroll hide-scrollbar"
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={sidebarItems.filter((item) =>
              item?.roles?.includes(dataUser?.role)
            )}
            style={{ backgroundColor: "#CAE8FE", color: "black" }}
          />
        </div>
      </Sider>
    </>
  );
}

export default Sidebar;
