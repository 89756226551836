import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

function LineChartApex(props) {

    const labels = props?.chartData.map((data) => {
        // console.log({data})
        return moment.unix(Number(data[props?.label])).format("HH:mm");
    });

    const series = [
        {
            name: props?.title,
            data: props?.chartData.map((data) => data[props?.selectedKey] || 0),
        },
    ];

    const options = {
        chart: {
            id: "area",
            // type: "area",
            // height: 350,
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels,
        },
        yaxis: {
            min: props?.min,
            max: props?.max,
            tickAmount: 5,
        },

        annotations: {},

        title: {
            text: props?.title,
            align: "left",
            style: {
                fontSize: "12px",
                // color: "#00A9FF",
                color: props?.textColor || "#00A9FF",
            },
        },
    };

    if (props?.thresholdsValue1 && props?.thresholdsValue2) {
        options.annotations.yaxis = [
            {
                y: props?.thresholdsValue2,
                borderColor: "#E11A62",
                label: {
                    borderColor: "#E11A62",
                    style: {
                        color: "#fff",
                        background: "#E11A62",
                    },
                    text: `Batas Bawah: ${props?.thresholdsValue2}`,
                },
            },
            {
                y: props?.thresholdsValue1,
                borderColor: "#E11A62",
                label: {
                    borderColor: "#E11A62",
                    style: {
                        color: "#fff",
                        background: "#E11A62",
                    },
                    text: `Batas Atas: ${props?.thresholdsValue1}`,
                },
            },
        ];
    } else if (props?.thresholdsValue1) {
        options.annotations.yaxis = [
            {
                y: props?.thresholdsValue1,
                borderColor: "#E11A62",
                label: {
                    borderColor: "#E11A62",
                    style: {
                        color: "#fff",
                        background: "#E11A62",
                    },
                    text: `Batas Atas: ${props?.thresholdsValue1}`,
                },
            },
        ];
    }


    return (
        <Chart
            options={options}
            series={series}
            type="area"
            height={props?.height || 460}
        />

    );
}

export default LineChartApex;
