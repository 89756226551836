import axios from "axios"
import { create } from "zustand"
import { AUTH_TOKEN } from "../helpers/constant"


export const useCompanyStore = create((set, get) => ({
    companies: [],
    company: {},
    createCompany: async (d) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.post(`${process.env.REACT_APP_API_BE}/company`, d, { headers: { authorization: `Bearer ${token}` } })

        await get().fetchDataCompany()
    },

    fetchDataCompany: async () => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/company`, { headers: { authorization: `Bearer ${token}` } })

        set((state) => ({
            data: (state.companies = response.data.data)
        }))
    },

    fetchCompanyById: async (id) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/company/${id}`, { headers: { authorization: `Bearer ${token}` } })

        set((state) => ({
            data: (state.company = response.data.data)
        }))
    },

    deleteCompany: async (id) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.delete(`${process.env.REACT_APP_API_BE}/company/${id}`, { headers: { authorization: `Bearer ${token}` } })

        await get().fetchCompany({})
    },

    editCompany: async (id, data) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.put(`${process.env.REACT_APP_API_BE}/company/${id}`, data, { headers: { authorization: `Bearer ${token}` } })

        await get().fetchCompanyById
    }
}))