import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/constant";

export const useUserStore = create((set, get) => ({
  users: [],
  user: {},
  userProfile: {},
  fetchAllUser: async ({ searchItems }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_BE}/user`, {
        params: {
          searchItems,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      set((state) => ({
        data: (state.users = response.data.data),
      }));
    } catch (error) { }
  },
  fetchUserByID: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/user/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.user = response.data.data || {}),
      }));
    } catch (error) { }
  },
  createNewUser: async (d) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/user`,
        d,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllUser();
    } catch (error) { }
  },
  deleteUser: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BE}/user/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllUser();
    } catch (error) { }
  },
  editUser: async (id, data) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BE}/user/${id}`,
        data,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllUser();
    } catch (error) { }
  },
  fetchUser: async () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_BE}/user/u`, {
        headers: { authorization: `Bearer ${token}` },
      });

      set((state) => ({
        data: (state.userProfile = response.data.data || {}),
      }));
    } catch (error) { }
  },
}));
