import React, { Fragment, useState, useEffect } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Layout } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import Home from "./pages/Home";
import Logger from "./pages/Logger";
import Company from "./pages/Company";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import DetailCompany from "./pages/DetailCompany";
import Sidebar from "./components/Sidebar";
import Swal from "sweetalert2";
import ManageUser from "./pages/ManageUser";
import IndustryType from "./pages/IndustryType";
import VerificationUser from "./pages/VerificationUser";
import AuthRoute from "./components/AuthRoute";
import { USER } from "./helpers/constant";
import PrivateRoute from "./components/PrivateRoute";
import CompanyProfile from "./pages/CompanyProfile";
import PageNotFound from "./pages/PageNotFound";
import { useAuthStore } from "./store/authStore";
import WelcomePage from "./pages/WelcomePage";
import ChartsPage from "./pages/user/ChartsPage";
import TablePage from "./pages/user/TablePage";
// import DataPerJam from "./pages/user/DataPerJam";
import DataPerHari from "./pages/user/DataPerHari";
import DetailLoggerNonUser from "./pages/DetailLoggerNonUser";
import DisplayPage from "./pages/user/DisplayPage";
import ManageSensor from "./pages/ManageSensor";
import ErrorLogs from "./pages/ErrorLogs";
import DailyReport from "./pages/DailyReport";

const { Header, Content } = Layout;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuthStore();

  const [collapsed, setCollapsed] = useState(true);
  const isUrlNotLogin = location.pathname !== "/login";
  const itemsSidebar = [
    // "/home",
    "/manage-user",
    "/industry-type",
    "/company",
    "/logger",
    "/company/logger",
    "/user/dashboard-user",
    // "/user/logger-data",
    "/",
    "/logger/:id",
    "/company/:id",
  ];

  const isUrlLogin = itemsSidebar.includes(location.pathname);

  const [dataUser, setDataUser] = useState({});

  const handleLogout = () => {
    localStorage.clear();
    Swal.fire({
      icon: "success",
      title: "Logout",
      text: "You already logout from ONLIMO Dashboard",
    });
    navigate("/login");
  };

  useEffect(() => {
    const userStorage = JSON.parse(localStorage.getItem(USER));
    setDataUser(userStorage);
    setUser(userStorage);
  }, [localStorage[USER]]);

  return (
    <>
      <Layout className="min-h-screen">
        {isUrlNotLogin && <Sidebar collapsed={collapsed} />}
        <Layout className="site-layout">
          {isUrlNotLogin && (
            <Fragment>
              <Header className="site-layout-background px-6 flex justify-between items-center">
                {collapsed ? (
                  <MenuUnfoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}

                <Button
                  className="flex items-center gap-2 font-bold"
                  onClick={() => handleLogout()}
                >
                  <LogoutOutlined className="" />
                  <p>Logout</p>
                </Button>
              </Header>
            </Fragment>
          )}

          <Content
            className="site-layout-background h-[calc(100vh-(64px+24px+24px))] overflow-scroll"
            style={
              isUrlNotLogin
                ? {
                  margin: "24px 16px",
                  padding: 24,
                  minHeight: 280,
                }
                : null
            }
          >
            <Routes>
              <Route element={<AuthRoute />}>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/verification/:token"
                  element={<VerificationUser />}
                />
              </Route>

              <Route element={<PrivateRoute />}>
                {["developer"].includes(dataUser?.role) && (
                  <Fragment>
                    <Route path="/manage-user" element={<ManageUser />} />
                    <Route path="/error-logs" element={<ErrorLogs />} />
                    <Route path="/daily-report" element={<DailyReport />} />
                  </Fragment>
                )}

                {["admin", "developer", "internal_bbt"].includes(
                  //di sini dunction harus dibeda2in per rolenya
                  dataUser?.role
                ) && (
                    <Fragment>
                      <Route path="/company" element={<Company />} />
                      <Route path="/company/:id" element={<DetailCompany />} />
                      {/* <Route path="/home" element={<Home />} /> */}
                      <Route path="/manage-user" element={<ManageUser />} />
                      <Route path="/manage-sensor" element={<ManageSensor />} />
                      <Route path="/logger" element={<Logger />} />
                      <Route path="/industry-type" element={<IndustryType />} />
                    </Fragment>
                  )}

                {["superuser", "developer"].includes(dataUser?.role) && (
                  <Route path="/company/logger" element={<CompanyProfile />} />
                )}

                {["user", "developer"].includes(dataUser?.role) && (
                  <>
                    <Route
                      path="/user/dashboard-user"
                      element={<DisplayPage />}
                    />

                    <Route
                      path="/user/logger-charts"
                      element={<ChartsPage />}
                    />
                    <Route
                      path="/user/data-per-menit"
                      element={<TablePage />}
                    />
                    {/* <Route path="/user/data-per-jam" element={<DataPerJam />} /> */}
                    <Route
                      path="/user/data-per-hari"
                      element={<DataPerHari />}
                    />
                  </>
                )}

                <Route path="/logger/:id" element={<DetailLoggerNonUser />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/" element={<WelcomePage />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default App;
