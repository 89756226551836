import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button, Progress } from "antd";
import LineChartApex from "../../components/LineChartsApex";
import { DatePicker } from "antd";

function ChartsComponent({ loggerDataByUID, company, logger, idStasiunLogger }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { RangePicker } = DatePicker;
  const { loggerDataCharts, fetchLoggerDataByUidForCharts } =
    useLoggerDataStore();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [loading, setLoading] = useState(false);
  const slicedData = loggerDataCharts.slice(0, 20);
  slicedData.sort((a, b) => a.datetime - b.datetime);



  const percentageData = () => {
    const get24Time = moment()
      .subtract(1, "days")
      .subtract(2, "minutes")
      .unix();

    const filteredData = loggerDataByUID.filter(
      (data) => data.datetime >= get24Time
    );

    const percentage = (filteredData.length / 24) * 100;

    return Math.min(100, percentage).toFixed(1);
  };

  const initialMinMax = {
    suhu: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // dhl: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tds: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    do: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    ph: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    turbidity: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    kedalaman: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    nitrat: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    amonia: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    cod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    bod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tss: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // swSG:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // salinitas:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // orp:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },

  };

  if (loggerDataCharts.length > 0) {
    loggerDataCharts.forEach((item) => {
      Object.keys(initialMinMax).forEach((property) => {
        initialMinMax[property].min = Math.min(
          initialMinMax[property].min,
          item[property]
        );
        initialMinMax[property].max = Math.max(
          initialMinMax[property].max,
          item[property]
        );
      });
    });
  } else {
    // Set initialMinMax values to 0 when loggerDataCharts is empty
    Object.keys(initialMinMax).forEach((property) => {
      initialMinMax[property].min = 0;
      initialMinMax[property].max = 0;
    });
  }

  const thresholdsData = [];
  if (logger.length > 0 && logger[0]?.thresholds_logger[0]?._id) {
    thresholdsData.push(logger[0]?.thresholds_logger[0]);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
      const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  useEffect(() => {
    // if (logger?.uid_logger) {
    // if (logger?.uid) {
    if (logger?.idstasiun) {
      fetchLoggerDataByUidForCharts({ idStasiunLogger, startDate, endDate });
    }
  }, [idStasiunLogger]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* h-full sm:h-[calc(100vh-220px)] */}

          <div className="flex flex-wrap gap-2 ">
            <RangePicker
              className=""
              onChange={onChange}
              allowClear={true}
              // showTime={{
              //   format: "HH:mm",
              // }}
              format="DD/MM/YYYY"
            />
            <Button onClick={onClear} type="primary">
              Reset Date
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-3">
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <div className=" flex items-center justify-center gap-4 w-1/2 mx-auto py-6 rounded-md">
                <Progress
                  type="circle"
                  percent={percentageData()}
                  trailColor="#DAEEFE"
                />
                {percentageData() === 100 ? (
                  <p className="font-bold text-4xl m-0 mb-2 text-center text-white">
                    {percentageData()}%
                  </p>
                ) : (
                  ""
                )}
              </div>
              <p className="text-center m-0 mb-6 text-[#041527] italic">
                * Pemenuhan data dalam 24 jam
              </p>
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Suhu"
                selectedKey="suhu"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxSuhu || []}
                // min={initialMinMax?.suhu?.min}
                // max={initialMinMax?.suhu?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            {/* <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Daya Hantar Listrik"
                selectedKey="dhl"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxDhl || []}
                // min={initialMinMax?.dhl?.min}
                // max={initialMinMax?.dhl?.max}
                height="186"
                textColor="#041527"
              />
            </div> */}
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="TDS"
                selectedKey="tds"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxTds || []}
                // min={initialMinMax?.tds?.min}
                // max={initialMinMax?.tds?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="DO"
                selectedKey="do"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxDo || []}
                // min={initialMinMax?.do?.min}
                // max={initialMinMax?.do?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 p-1 rounded-md shadow-charts">
              <LineChartApex
                title="pH"
                selectedKey="ph"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxpH || []}
                thresholdsValue2={thresholdsData[0]?.minpH || []}
                // min={initialMinMax?.ph?.min - 2}
                // max={initialMinMax?.ph?.max + 1}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Turbidity"
                selectedKey="turbidity"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxTurbidity || []}
                // min={initialMinMax?.turbidity?.min}
                // max={initialMinMax?.turbidity?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Kedalaman"
                selectedKey="kedalaman"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxKedalaman || []}
                // min={initialMinMax?.kedalaman?.min}
                // max={initialMinMax?.kedalaman?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Nitrat"
                selectedKey="nitrat"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxNitrat || []}
                // min={initialMinMax?.nitrat?.min}
                // max={initialMinMax?.nitrat?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Amonia"
                selectedKey="amonia"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxAmonia || []}
                // min={initialMinMax?.amonia?.min}
                // max={initialMinMax?.amonia?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="COD"
                selectedKey="cod"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxCod || []}
                // min={initialMinMax?.cod?.min}
                // max={initialMinMax?.cod?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="BOD"
                selectedKey="bod"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxBod || []}
                // min={initialMinMax?.bod?.min}
                // max={initialMinMax?.bod?.max}
                height="186"
                textColor="#041527"
              />
            </div>
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="TSS"
                selectedKey="tss"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxTss || []}
                // min={initialMinMax?.tss?.min}
                // max={initialMinMax?.tss?.max}
                height="186"
                textColor="#041527"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ChartsComponent;
