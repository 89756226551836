import React, { useEffect, useState } from "react";
import { useUserStore } from "../store/userStore";
import { useCompanyStore } from "../store/companyStore";
import { Button, Checkbox, Modal } from "antd";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";

function Profile() {
    const navigate = useNavigate();
    const { fetchUser, userProfile } = useUserStore();
    const { fetchCompanyById } = useCompanyStore();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const { changePasswordUser } = useAuthStore();
    const companyId = userProfile?.company_id;

    const shapeYup = {
        password: Yup.string()
            .required("This field is required")
            .min(8, "Password too short"),
        newPassword: Yup.string()
            .required("This field is required")
            .min(8, "Password too short"),
        repeatPassword: Yup.string()
            .required("This field is required")
            .oneOf(
                [Yup.ref("newPassword"), null],
                "Password must match with new password"
            ),
    };
    const newPasswordSchema = Yup.object().shape(shapeYup);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const changePassword = async (value, actions) => {
        try {
            setIsLoading(true);
            changePasswordUser(value);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Success change your password",
            });
            setIsLoading(false);
            localStorage.clear();
            navigate("/login");
        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!!",
            });
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (companyId) {
            fetchCompanyById(companyId);
        }
    }, [companyId]);

    return (
        <div>
            <div>
                <div>
                    <p className="text-lg font-bold m-0">Account information</p>
                </div>
                <div className="w-[70%] mt-4">
                    <div className="flex flex-col sm:flex-row">
                        <div className="w-[30%]">
                            <p className="font-bold py-1 text-slate-600">Username</p>
                        </div>
                        <p className="bg-slate-200 px-2 py-1 rounded-sm border-solid border-slate-400 w-[180px]">
                            {userProfile.username}
                        </p>
                    </div>

                    <div className="flex flex-col sm:flex-row mt-2">
                        <div className="w-[30%]">
                            <p className="font-bold py-1 text-slate-600">Email</p>
                        </div>
                        <p className="bg-slate-200 px-2 py-1 rounded-sm border-solid border-slate-400 w-[180px]">
                            {userProfile.email}
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex gap-2 mt-7">
                <Button onClick={showModal}>Change Password</Button>
            </div>

            <Modal
                title="Change Password"
                open={isModalOpen}
                okText="Create"
                onCancel={handleCancel}
                footer={null}
                className="sm:w-1/2"
            >
                <Formik
                    initialValues={{
                        newPassword: "",
                        password: "",
                    }}
                    validationSchema={newPasswordSchema}
                    onSubmit={(value) => {
                        changePassword(value);
                    }}
                >
                    {(props) => {
                        return (
                            <>
                                <Form className="mt-8 space-y-6" action="#" method="POST">
                                    <input type="hidden" name="remember" defaultValue="true" />

                                    <div className="rounded-md">
                                        <div className="my-6">
                                            <label
                                                htmlFor="Current Password"
                                                className="block mb-2 text-sm  text-gray-900"
                                            >
                                                Current Password
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    id="password"
                                                    name="password"
                                                    type={show ? "text" : "password"}
                                                    required
                                                    className="pl-4 shadow-sm relative block w-[94%] h-8 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                />
                                            </div>
                                            <ErrorMessage
                                                component="div"
                                                name="password"
                                                style={{ color: "red", fontSize: "12px" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="rounded-md">
                                        <div className="my-6">
                                            <label
                                                htmlFor="New Password"
                                                className="block mb-2 text-sm  text-gray-900"
                                            >
                                                New Password
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type={show ? "text" : "password"}
                                                    required
                                                    className="pl-4 shadow-sm relative block w-[94%] h-8 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                />
                                            </div>
                                            <ErrorMessage
                                                component="div"
                                                name="newPassword"
                                                style={{ color: "red", fontSize: "12px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="rounded-md">
                                        <div className="my-6">
                                            <label
                                                htmlFor="Repeat New Password"
                                                className="block mb-2 text-sm  text-gray-900"
                                            >
                                                Repeat New Password
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    id="repeatPassword"
                                                    name="repeatPassword"
                                                    type={show ? "text" : "password"}
                                                    required
                                                    className="pl-4 shadow-sm relative block w-[94%] h-8 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                />
                                            </div>
                                            <ErrorMessage
                                                component="div"
                                                name="repeatPassword"
                                                style={{ color: "red", fontSize: "12px" }}
                                            />
                                        </div>
                                    </div>
                                    <Checkbox
                                        style={{
                                            background: "transparent",
                                            color: "black",
                                            marginTop: "0px",
                                        }}
                                        onChange={(event) => setShow(event.target.checked)}
                                    >
                                        <p style={{ fontSize: "14px" }}>Show Password</p>
                                    </Checkbox>

                                    <div className="flex gap-2 flex-wrap">
                                        <Button
                                            key="submit"
                                            type="primary"
                                            htmlType="submit"
                                            // type="submit"
                                            loading={isLoading}
                                        >
                                            Save Password
                                        </Button>
                                        <Button key="back" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Modal>
        </div>
    );
}

export default Profile;
