import { Button, Table, Modal, Input, Select, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useCompanyStore } from "../store/companyStore";
import Swal from "sweetalert2";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useUserStore } from "../store/userStore";
import { useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";

function ManageUser() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    users,
    fetchAllUser,
    createNewUser,
    deleteUser,
    editUser,
    fetchUserByID,
  } = useUserStore();
  const { fetchDataCompany, companies } = useCompanyStore();
  const [formEditUser, setFormEditUser] = useState({
    role: "",
  });
  const [idUser, setIdUser] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const { Search } = Input;
  const searchItems = searchParams.get("searchItems");

  const debouncedFetchAllUser = _debounce((value) => {
    fetchAllUser({ searchItems: value });
  }, 1000);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchParams((params) => {
      params.set("searchItems", value);
      return params;
    });
    debouncedFetchAllUser(value);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (value, record) => `${record?.username || "-"}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value, record) => `${record?.email || "-"}`,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value, record) => `${record?.role || "-"}`,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (value, record) => `${record?.company_id?.company_name || "-"}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => handleEditUser(record)}
            className="border-blue-500 border-1 text-blue-500 "
          >
            Edit
          </Button>

          <Button danger onClick={() => handleConfirmDelete(record._id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleEditUser = (record) => {
    setIdUser(record._id);
    setFormEditUser({
      role: record.role,
    });
    setIsEditModalOpen(true);
  };

  const handleEditUserForm = async (event) => {
    const key = event.target.name;

    setFormEditUser({ ...formEditUser, [key]: event.target.value });
  };

  const handleConfirmationEditUser = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Edit User",
    });
    if (result.isConfirmed) {
      onClickSubmitEditHandler();
    }
  };

  const onClickSubmitEditHandler = async () => {
    try {
      setIsLoading(true);
      editUser(idUser, formEditUser);
      setIsLoading(false);
      setIsEditModalOpen(false);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success edit user",
      });

      setFormEditUser({
        role: "",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
      setIsLoading(false);
      setIsEditModalOpen(false);
    }
  };

  //function for modal create industry type
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreateUser = async (value, actions) => {
    const d = {
      username: value.username || "",
      email: value.email || "",
      role: value.role || "",
      company_id: selectedCompany || "",
    };

    createNewUser(d);
    setIsModalOpen(false);

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Success Create a new user",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteUser = async (id) => {
    try {
      deleteUser(id);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success delete user",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    });
    if (result.isConfirmed) {
      handleDeleteUser(id);
    }
  };

  //select company
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  const onChange = (value, label) => {
    setSelectedCompany(value);
  };

  useEffect(() => {
    return () => debouncedFetchAllUser.cancel();
  }, [debouncedFetchAllUser]);

  useEffect(() => {
    fetchAllUser({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (idUser) {
      fetchUserByID(idUser);
    }
  }, [idUser]);

  useEffect(() => {
    fetchDataCompany({});
  }, []);

  return (
    <div>
      <p className="text-xl font-bold">Manage User</p>
      <div className="flex mb-6 mt-2 justify-between">
        <div className="flex gap-2">
          <Button type="primary" onClick={showModal}>
            Create new user
          </Button>
        </div>

        <Search
          placeholder="Search user"
          allowClear
          onChange={onSearchInputChange}
          style={{
            width: 200,
          }}
        />
      </div>

      <Table
        dataSource={users}
        columns={columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Create New User"
        open={isModalOpen}
        okText="Create"
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          initialValues={{
            username: "",
            email: "",
            role: "",
            company_id: "",
          }}
          onSubmit={(value, actions) => {
            handleCreateUser(value, actions);
          }}
        >
          {(props) => {
            return (
              <div>
                <Form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="my-6">
                    <label
                      htmlFor="username"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <Field
                        id="username"
                        name="username"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Username"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="username"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Email"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="email"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="role"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Role
                    </label>

                    <Radio.Group
                      onChange={(e) =>
                        props.setFieldValue("role", e.target.value)
                      }
                      value={props.values.role}
                    >
                      <Radio value="developer">Developer</Radio>
                      <Radio value="admin">Admin</Radio>
                      <Radio value="internal_bbt">Internal BBT</Radio>
                      <Radio value="superuser">Superuser</Radio>
                      <Radio value="user">User</Radio>
                    </Radio.Group>

                    {/* <div className="mt-2">
                      <Field
                        id="role"
                        name="role"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Role"
                      />
                    </div> */}
                    <ErrorMessage
                      component="div"
                      name="role"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="company_id"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company
                    </label>
                    <Select
                      showSearch
                      id="company_id"
                      name="company_id"
                      required
                      placeholder="Company"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      className="w-full"
                      options={(companies || []).map((data) => ({
                        key: data._id,
                        value: data._id,
                        label: data.company_name,
                      }))}
                    />
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>

                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        title="Edit Role User"
        open={isEditModalOpen}
        okText="Edit"
        onCancel={handleCancelEditModal}
        footer={null}
      >
        <p className="mb-1">Role</p>
        <Radio.Group
          onChange={handleEditUserForm} // You can keep using the same handleEditUserForm function
          name="role"
          value={formEditUser.role} // Bind the selected value to the form state
        >
          <Radio value="developer">Developer</Radio>
          <Radio value="admin">Admin</Radio>
          <Radio value="internal_bbt">Internal BBT</Radio>
          <Radio value="superuser">Superuser</Radio>
          <Radio value="user">User</Radio>
        </Radio.Group>

        {/* <Input
          onChange={handleEditUserForm}
          name="role"
          id="role"
          value={formEditUser.role}
        /> */}

        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancelEditModal}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              handleConfirmationEditUser();
            }}
          >
            Edit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ManageUser;
