import axios from "axios"
import { create } from "zustand"
import { AUTH_TOKEN } from "../helpers/constant"


export const useReportStore = create((set, get) => ({
    reports: [],
    fetchAllReport: async ({ startDate, endDate }) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/report`, { params: { startDate, endDate }, headers: { authorization: `Bearer ${token}` } })

        set((state) => ({
            data: (state.reports = response.data.data)
        }))
    },
    createReportingDataLogger: async (d) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        let response = await axios.get(`${process.env.REACT_APP_API_BE}/report/data`, { headers: { authorization: `Bearer ${token}` } })

        await get().fetchAllReport()
    },
}))