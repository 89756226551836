import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function VerificationUser() {
    let { token } = useParams()
    const navigate = useNavigate()

    const tokenVerification = async () => {
        try {
            if (token) {
                let response = await axios.post(`${process.env.REACT_APP_API_BE}/auth/verification`,
                    {},
                    { headers: { authorization: `Bearer ${token}` } })


                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: response.data.message,
                        footer: "",
                    })

                    navigate("/login");
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!!",
            });
        }
    }

    useEffect(() => {
        tokenVerification()
    }, [])

    return
}

export default VerificationUser