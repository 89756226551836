import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Checkbox } from "antd";
import { useAuthStore } from "../store/authStore";

function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const { loginUserHandler } = useAuthStore();

    const loginSchema = Yup.object().shape({
        input: Yup.string().required("This field is required"),
        password: Yup.string()
            .required("This field is required")
            .min(8, "Password too short"),
    });

    const loginUser = async (value, actions) => {
        const data = {
            password: value.password,
            input: value.input,
        };

        setIsLoading(true);
        loginUserHandler(data);
        setIsLoading(false);
    };

    return (
        // <div>
        //     <div>
        //         <Formik
        //             initialValues={{
        //                 input: "",
        //                 password: "",
        //             }}
        //             validationSchema={loginSchema}
        //             onSubmit={(value, actions) => {
        //                 loginUser(value, actions);
        //             }}
        //         >
        //             {(props) => {
        //                 return (
        //                     <>
        //                         <div className="flex w-screen min-h-screen h-full">
        //                             <div className="flex justify-center mx-auto flex-col p-14">
        //                                 <div className="text-center">
        //                                     <p className="text-3xl font-bold mb-2">
        //                                         Onlimo Platform
        //                                     </p>
        //                                     <p className="text-xl text-slate-500">
        //                                         Log in to your account
        //                                     </p>
        //                                 </div>
        //                                 <div>
        //                                     <Form className="mt-8 space-y-6" action="#" method="POST">
        //                                         <input
        //                                             type="hidden"
        //                                             name="remember"
        //                                             defaultValue="true"
        //                                         />
        //                                         <div className="rounded-md">
        //                                             <div className="my-6">
        //                                                 <label
        //                                                     htmlFor="input"
        //                                                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        //                                                 >
        //                                                     Email or Username
        //                                                 </label>
        //                                                 <div className="mt-2">
        //                                                     <Field
        //                                                         id="input"
        //                                                         name="input"
        //                                                         type="input"
        //                                                         autoComplete="input"
        //                                                         required
        //                                                         className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
        //                                                         placeholder="Email / Username"
        //                                                     />
        //                                                 </div>
        //                                                 <ErrorMessage
        //                                                     component="div"
        //                                                     name="input"
        //                                                     style={{ color: "red", fontSize: "12px" }}
        //                                                 />
        //                                             </div>

        //                                             <div className="mt-6 mb-2">
        //                                                 <label
        //                                                     htmlFor="password"
        //                                                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        //                                                 >
        //                                                     Password
        //                                                 </label>
        //                                                 <div className="mt-2">
        //                                                     <Field
        //                                                         id="password"
        //                                                         name="password"
        //                                                         type={show ? "text" : "password"}
        //                                                         required
        //                                                         className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        //                                                         placeholder="Password"
        //                                                         autoComplete="new-password"
        //                                                     />
        //                                                 </div>
        //                                                 <ErrorMessage
        //                                                     component="div"
        //                                                     name="password"
        //                                                     style={{ color: "red", fontSize: "12px" }}
        //                                                 />
        //                                             </div>
        //                                         </div>
        //                                         <Checkbox
        //                                             style={{
        //                                                 background: "transparent",
        //                                                 color: "black",
        //                                                 marginTop: "0px",
        //                                             }}
        //                                             onChange={(event) => setShow(event.target.checked)}
        //                                         >
        //                                             <p style={{ fontSize: "14px" }}>Show Password</p>
        //                                         </Checkbox>
        //                                         <div>
        //                                             <Button
        //                                                 loading={isLoading}
        //                                                 htmlType="submit"
        //                                                 type="primary"
        //                                                 className="w-full rounded-md text-white bg-blue-500 border-none h-11 text-lg"
        //                                             >
        //                                                 Login
        //                                             </Button>
        //                                         </div>
        //                                     </Form>
        //                                 </div>

        //                                 <div className="flex flex-col items-center gap-1 mt-16">
        //                                     <p className="m-0 text-slate-400">Powered By</p>
        //                                     <p className="font-bold text-lg m-0 text-slate-600">
        //                                         Buana Biru Teknindo
        //                                     </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </>
        //                 );
        //             }}
        //         </Formik>
        //     </div>
        // </div>

        <>
            <div className="flex w-full h-full">
                <div className="w-[30%]">

                    <img src="/assets/logo-bbt.png" alt="" className="m-0" width={144} />

                    <Formik
                        initialValues={{
                            input: "",
                            password: "",
                        }}
                        validationSchema={loginSchema}
                        onSubmit={(value, actions) => {
                            loginUser(value, actions);
                        }}
                    >
                        {(props) => {
                            return (

                                <div className="flex justify-center mx-auto flex-col p-14 mt-11">
                                    <div className="text-center">
                                        <p className="text-3xl font-bold mb-2">
                                            <span className="text-[#0174B8]">Onlimo</span> Platform
                                        </p>
                                        <p className="text-xl text-slate-500">
                                            Log in to your account
                                        </p>
                                    </div>
                                    <div>
                                        <Form className="mt-8 space-y-6" action="#" method="POST">
                                            <input
                                                type="hidden"
                                                name="remember"
                                                defaultValue="true"
                                            />
                                            <div className="rounded-md">
                                                <div className="my-6">
                                                    <label
                                                        htmlFor="input"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Email or Username
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            id="input"
                                                            name="input"
                                                            type="input"
                                                            autoComplete="input"
                                                            required
                                                            className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                                            placeholder="Email / Username"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        component="div"
                                                        name="input"
                                                        style={{ color: "red", fontSize: "12px" }}
                                                    />
                                                </div>

                                                <div className="mt-6 mb-2">
                                                    <label
                                                        htmlFor="password"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Password
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            id="password"
                                                            name="password"
                                                            type={show ? "text" : "password"}
                                                            required
                                                            className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                                                            placeholder="Password"
                                                            autoComplete="new-password"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        component="div"
                                                        name="password"
                                                        style={{ color: "red", fontSize: "12px" }}
                                                    />
                                                </div>
                                            </div>
                                            <Checkbox
                                                style={{
                                                    background: "transparent",
                                                    color: "black",
                                                    marginTop: "0px",
                                                }}
                                                onChange={(event) => setShow(event.target.checked)}
                                            >
                                                <p style={{ fontSize: "14px" }}>Show Password</p>
                                            </Checkbox>
                                            <div>
                                                <Button
                                                    loading={isLoading}
                                                    htmlType="submit"
                                                    type="primary"
                                                    className="w-full rounded-md text-white bg-[#0174B8] border-none h-11 text-lg"
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>


                            );
                        }}
                    </Formik>

                    <div className="flex flex-col items-center gap-1">
                        <p className="m-0 text-slate-400">Powered By</p>
                        <p className="font-bold text-lg m-0 text-slate-600">
                            Buana <span className="text-[#0174B8]">Biru</span> Teknindo
                        </p>
                    </div>
                </div>

                <div className="w-[70%] login-bg">
                    <div className="w-[36%] ml-32 mt-20">
                        <p className="tracking-[.28em] text-6xl text-white font-bold mb-4">ONLIMO</p>
                        <p className="tracking-wide text-lg text-white m-0">Sistem Pemantauan Kualitas Air Secara Kontinu, Otomatis dan Dalam Jaringan</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
