import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useReportStore } from "../store/reportStore";
import { USER } from "../helpers/constant";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../components/DropdownItems";
import moment from "moment";

function DailyReport() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const { reports, fetchAllReport } = useReportStore()
    const [dataUser, setDataUser] = useState({});
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const { RangePicker } = DatePicker;
    const { CSVDownloader, Type } = useCSVDownloader();

    const convertDate = (date) => {
        const numDate = Number(date);
        return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
    };



    const columns = [
        {
            title: "Received Data Date",
            dataIndex: "datetime",
            key: "datetime",
            render: (value, record) => convertDate(record.datetime),

        },
        {
            title: "Percentage Data",
            dataIndex: "percentageData",
            key: "percentageData",
            render: (value, record) =>
                `${value}%`,
        },
        {
            title: "ID Stasiun",
            dataIndex: "idstasiun",
            key: "idstasiun",
            render: (value, record) =>
                `${record?.idstasiun || "-"}`,
        },
        {
            title: "Suhu",
            dataIndex: "suhu",
            key: "suhu",
            render: (value, record) =>
                `${record?.suhu || "-"}`,
        },
        {
            title: "TDS",
            dataIndex: "tds",
            key: "tds",
            render: (value, record) =>
                `${record?.tds || "-"}`,
        },
        {
            title: "DO",
            dataIndex: "do",
            key: "do",
            render: (value, record) =>
                `${record?.do || "-"}`,
        },
        {
            title: "PH",
            dataIndex: "ph",
            key: "ph",
            render: (value, record) =>
                `${record?.ph || "-"}`,
        },
        {
            title: "Turbidity",
            dataIndex: "turbidity",
            key: "turbidity",
            render: (value, record) =>
                `${record?.turbidity || "-"}`,
        },
        {
            title: "Kedalaman",
            dataIndex: "kedalaman",
            key: "kedalaman",
            render: (value, record) =>
                `${record?.kedalaman || "-"}`,
        },
        {
            title: "Nitrat",
            dataIndex: "nitrat",
            key: "nitrat",
            render: (value, record) =>
                `${record?.nitrat || "-"}`,
        },
        {
            title: "Amonia",
            dataIndex: "amonia",
            key: "amonia",
            render: (value, record) =>
                `${record?.amonia || "-"}`,
        },
        {
            title: "COD",
            dataIndex: "cod",
            key: "cod",
            render: (value, record) =>
                `${record?.cod || "-"}`,
        },
        {
            title: "BOD",
            dataIndex: "bod",
            key: "bod",
            render: (value, record) =>
                `${record?.bod || "-"}`,
        },
        {
            title: "TSS",
            dataIndex: "tss",
            key: "tss",
            render: (value, record) =>
                `${record?.tss || "-"}`,
        },
    ];


    const downloadedDataLogger = reports.map((data) => {
        return {
            idstasiun: data?.idstasiun,
            datetime: convertDate(data?.datetime),
            percentageData: data.percentageData,
            tanggal: data?.tanggal,
            jam: data?.jam,
            suhu: data?.suhu,
            tds: data?.tds,
            do: data?.do,
            ph: data?.ph,
            turbidity: data?.turbidity,
            kedalaman: data?.kedalaman,
            nitrat: data?.nitrat,
            amonia: data?.amonia,
            cod: data?.cod,
            bod: data?.bod,
            tss: data?.tss,
        };
    });

    const downloadXLSX = () => {
        const xlsxData = downloadedDataLogger;

        const ws = XLSX.utils.json_to_sheet(xlsxData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

        XLSX.writeFile(wb, "report.xlsx");
    };

    const onChange = (checkedValues) => {
        if (checkedValues) {
            let startDate = checkedValues[0].$d;
            let endDate = checkedValues[1].$d;

            // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
            const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
            const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
            const dateFormat = "DD/MM/YYYY HH:mm:ss";
            const timestamp1 = moment(date1, dateFormat).unix();
            const timestamp2 = moment(date2, dateFormat).unix();

            // setLoading(true)

            setSearchParams((params) => {
                params.set("startDate", timestamp1);
                params.set("endDate", timestamp2);
                return params;
            });

            // setLoading(false)
        }
    };

    const onClear = () => {
        setSearchParams((params) => {
            params.delete("startDate");
            params.delete("endDate");
            return params;
        });
    };

    const downloadItems = [
        {
            key: "2",
            label: (
                <p className="m-0">
                    <CSVDownloader
                        type={Type}
                        filename={"filename"}
                        bom={true}
                        config={{
                            delimiter: ";",
                        }}
                        data={downloadedDataLogger}
                        className="text-slate-800"
                    >
                        CSV
                    </CSVDownloader>
                </p>
            ),
        },
        {
            key: "3",
            label: (
                <p className="m-0" onClick={downloadXLSX}>
                    XLSX
                </p>
            ),
        },
    ];



    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    //

    useEffect(() => {
        fetchAllReport({ startDate, endDate });
    }, [startDate, endDate]);

    useEffect(() => {
        setDataUser(JSON.parse(localStorage.getItem(USER)));
    }, [localStorage[USER]]);

    return (
        <div>
            <p className="text-xl font-bold">Daily Reporting Data Logger</p>

            <div className="flex mb-6 mt-2 flex-between gap-4">

                <RangePicker
                    className="mb-4"
                    onChange={onChange}
                    allowClear={true}
                    // showTime={{
                    //   format: "HH:mm",
                    // }}
                    format="DD/MM/YYYY HH:mm"
                />
                <Button onClick={onClear} type="primary">
                    Reset Date
                </Button>

                <DropdownItems items={downloadItems} title="Download" />
            </div>

            <Table
                dataSource={reports}
                columns={columns}
                // pagination={{ pageSize: 5 }}
                scroll={{ x: "max-content" }}
            />


        </div>
    );
}

export default DailyReport;
