import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../../store/loggerStore";
import { useCompanyStore } from "../../store/companyStore";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Progress, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import LineChartApex from "../../components/LineChartsApex";
import { useSensorStore } from "../../store/sensorStore";
// import { Spin } from 'antd'

function DisplayPage() {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  // const { loggerDataPerMenit, fetchDataPerMenit } = useLoggerDataStore();
  const {
    loggerDataByUID,
    loggerDataCharts,
    fetchLoggerDataByUidForCharts,
    fetchLoggerDataByUid,
  } = useLoggerDataStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const loggerSensor = [];
  const companyId = loggerPerUser[0]?.company_id || "";
  // const uidLogger = loggerPerUser[0]?.uid_logger || "";
  // const uidLogger = loggerPerUser[0]?.uid || "";
  const idStasiunLogger = loggerPerUser[0]?.idstasiun || "";
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [loading, setLoading] = useState(true);
  const searchItems = searchParams.get("searchItems");

  const slicedData = loggerDataCharts.slice(0, 5);
  slicedData.sort((a, b) => a.datetime - b.datetime);

  // console.log({ loggerDataCharts })
  // console.log({ slicedData })
  // console.log({ loggerPerUser })

  // to check what sensors are active in a logger
  if ((loggerPerUser[0]?.sensors?.length > 0) ) {
    const dataSensor = loggerPerUser[0]?.sensors;
    // console.log({ dataSensor1: dataSensor })

    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  } else if (
    (loggerPerUser[0]?.sensors?.length === 0)
  ) {
    const dataSensor = loggerPerUser[0]?.industry_type[0]?.sensors;
    // console.log({dataSensor2: dataSensor})
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }


  const isSensorNameIncluded = (sensorName) => {
    if (loggerSensor.length > 0) {
      return loggerSensor[0].includes(sensorName);
    }
  };
  //

  const percentageData = () => {
    // const get24Time = moment().subtract(1, "days").unix();
    const get24Time = moment()
      .subtract(1, "days")
      .subtract(2, "minutes")
      .unix();

    const filteredData = loggerDataByUID.filter(
      (data) => data.datetime >= get24Time
    );

    const percentage = (filteredData.length / 24) * 100;

    return Math.min(100, percentage).toFixed(1);
  };

  const initialMinMax = {
    suhu: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // dhl: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tds: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    do: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    ph: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    turbidity: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    kedalaman: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    nitrat: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    amonia: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    cod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    bod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tss: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // swSG:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // salinitas:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    // orp:{ min: Number.MAX_VALUE, max: Number.MIN_VALUE },
  };

  if (loggerDataCharts.length > 0) {
    loggerDataCharts.forEach((item) => {
      Object.keys(initialMinMax).forEach((property) => {
        initialMinMax[property].min = Math.min(
          initialMinMax[property].min,
          item[property]
        );
        initialMinMax[property].max = Math.max(
          initialMinMax[property].max,
          item[property]
        );
      });
    });
  } else {
    // Set initialMinMax values to 0 when loggerDataCharts is empty
    Object.keys(initialMinMax).forEach((property) => {
      initialMinMax[property].min = 0;
      initialMinMax[property].max = 0;
    });
  }

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const thresholdsData = [];
  if (loggerPerUser.length > 0 && loggerPerUser[0]?.thresholds_logger[0]?._id) {
    thresholdsData.push(loggerPerUser[0]?.thresholds_logger[0]);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (loggerPerUser) {
      fetchLoggerByUserId();
    }
  }, [loggerPerUser._id]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId]);

  //fetching data first time
  // useEffect(() => {
  //   if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
  //     fetchLoggerDataByUid({ uidLogger, startDate, endDate });
  //   }
  // }, [uidLogger]);

  useEffect(() => {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid) {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.idstasiun) {

      const timerId = setTimeout(() => {
        fetchLoggerDataByUid({ idStasiunLogger, startDate, endDate });
        setLoading(false);
      }, 2000);
      return () => clearTimeout(timerId);
    }
  }, [idStasiunLogger]);

  //fetching data every 2 minutes
  useEffect(() => {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid) {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.idstasiun) {
      const interval = setInterval(() => {
        fetchLoggerDataByUid({ idStasiunLogger, startDate, endDate });
      }, 120000);
      return () => clearInterval(interval);
    }
  }, [idStasiunLogger, startDate, endDate]);

  //fetching data first time
  useEffect(() => {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid) {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.idstasiun) {
      const timerId = setTimeout(() => {
        fetchLoggerDataByUidForCharts({ idStasiunLogger, startDate, endDate });
        setLoading(false);
      }, 2000);
      return () => clearTimeout(timerId);
    }

  }, [idStasiunLogger]);

  // //fetching data every 2 minutes
  useEffect(() => {
    // if (loggerPerUser[0]?.uid_logger) {
    // if (loggerPerUser[0]?.uid) {
    if (loggerPerUser[0]?.idstasiun) {
      const interval = setInterval(() => {
        fetchLoggerDataByUidForCharts({ idStasiunLogger, startDate, endDate });
      }, 120000);
      return () => clearInterval(interval);
    }
  }, [idStasiunLogger, startDate, endDate]);


  return (
    <>

      <div>
        {loading ? (
          <div className="fixed inset-0 flex flex-col items-center justify-center">
            {/* <Spin className="" size="large" /> */}
            <p className="text-blue-400 m-0 text-lg">Loading...</p>

          </div>
        ) : (
          <div className="">
            <p className="text-xl font-bold text-center m-0 text-[#008FFB]">
              {loggerPerUser[0]?.logger_name || "-"}
            </p>

            <p className="text-center text-xs text-slate-500 m-0 mt-1">
              Latest Data from {convertDate(loggerDataByUID[0]?.datetime)}
            </p>

            <div className="w-full flex mt-4">
              {/* <div className="w-2/3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-1 sm:justify-between text-center justify-center">

                {isSensorNameIncluded("suhu") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Suhu
                    </p>
                    {loggerDataByUID[0]?.suhu <= thresholdsData[0]?.maxSuhu ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.suhu}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">°C</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.suhu}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">°C</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("dhl") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Daya Hantar Listrik
                    </p>
                    {loggerDataByUID[0]?.dhl <= thresholdsData[0]?.maxDhl ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.dhl}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">μS/cm</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.dhl}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">μS/cm</p>
                      </div>
                    )}

                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("tds") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      TDS
                    </p>
                    {loggerDataByUID[0]?.tds <= thresholdsData[0]?.maxTds ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.tds}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.tds}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("do") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      DO
                    </p>
                    {loggerDataByUID[0]?.do <= thresholdsData[0]?.maxDo ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.do}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.do}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("pH") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      pH
                    </p>
                    {loggerDataByUID[0]?.ph >= thresholdsData[0]?.minpH &&
                      loggerDataByUID[0]?.ph <= thresholdsData[0]?.maxpH ? (
                      <p className="font-semibold text-3xl text-black m-0">{loggerDataByUID[0]?.ph}</p>
                    ) : (
                      <p className="font-semibold text-3xl text-red-600 m-0">
                        {loggerDataByUID[0]?.ph}
                      </p>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("turbidity") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Turbidity
                    </p>
                    {loggerDataByUID[0]?.turbidity <= thresholdsData[0]?.maxTurbidity ? (

                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.turbidity}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">NTU</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.turbidity}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">NTU</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("kedalaman") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Kedalaman
                    </p>
                    {loggerDataByUID[0]?.kedalaman <= thresholdsData[0]?.maxKedalaman ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.kedalaman}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">m</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.kedalaman}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">m</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("nitrat") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Nitrat
                    </p>
                    {loggerDataByUID[0]?.nitrat <= thresholdsData[0]?.maxNitrat ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.nitrat}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.nitrat}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("amonia") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Amonia
                    </p>
                    {loggerDataByUID[0]?.amonia <= thresholdsData[0]?.maxAmonia ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.amonia}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.amonia}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("cod") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      COD
                    </p>
                    {loggerDataByUID[0]?.cod <= thresholdsData[0]?.maxCod ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.cod}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.cod}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("bod") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      BOD
                    </p>
                    {loggerDataByUID[0]?.bod <= thresholdsData[0]?.maxBod ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.bod}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.bod}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}


                {isSensorNameIncluded("tss") ? (
                  <div className=" bg-slate-200 border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      TSS
                    </p>
                    {loggerDataByUID[0]?.tss <= thresholdsData[0]?.maxTss ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.tss}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.tss}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div> */}

              <div className="w-2/3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:justify-between text-center justify-center">

                {isSensorNameIncluded("suhu") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Suhu
                    </p>
                    {loggerDataByUID[0]?.suhu <= thresholdsData[0]?.maxSuhu ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.suhu}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">°C</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.suhu}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">°C</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {/* {isSensorNameIncluded("dhl") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Daya Hantar Listrik
                    </p>
                    {loggerDataByUID[0]?.dhl <= thresholdsData[0]?.maxDhl ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.dhl}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">μS/cm</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.dhl}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">μS/cm</p>
                      </div>
                    )}

                  </div>
                ) : (
                  ""
                )} */}

                {isSensorNameIncluded("tds") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      TDS
                    </p>
                    {loggerDataByUID[0]?.tds <= thresholdsData[0]?.maxTds ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.tds}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.tds}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("do") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      DO
                    </p>
                    {loggerDataByUID[0]?.do <= thresholdsData[0]?.maxDo ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.do}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.do}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("pH") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      pH
                    </p>
                    {loggerDataByUID[0]?.ph >= thresholdsData[0]?.minpH &&
                      loggerDataByUID[0]?.ph <= thresholdsData[0]?.maxpH ? (
                      <p className="font-semibold text-3xl text-black m-0">{loggerDataByUID[0]?.ph}</p>
                    ) : (
                      <p className="font-semibold text-3xl text-red-600 m-0">
                        {loggerDataByUID[0]?.ph}
                      </p>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("turbidity") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Turbidity
                    </p>
                    {loggerDataByUID[0]?.turbidity <= thresholdsData[0]?.maxTurbidity ? (

                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.turbidity}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">NTU</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.turbidity}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">NTU</p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

              </div>

              {/* <div className="w-1/3"> */}
              <div className="w-1/3 flex flex-col items-center justify-between">
                <Progress
                  type="circle"
                  percent={percentageData()}
                  trailColor="#DAEEFE"
                />
                {percentageData() === 100 ? (
                  <p className="font-bold text-4xl m-0 mb-2 text-center text-white ">
                    {percentageData()}%
                  </p>
                ) : (
                  ""
                )}

                <p className="italic text-center text-xs text-slate-500 m-0 pt-1">
                  * Pemenuhan data dalam 24 jam
                </p>
              </div>

              {/* </div> */}

              <div className="w-2/3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-1 sm:justify-between text-center justify-center">
                {isSensorNameIncluded("kedalaman") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Kedalaman
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.kedalaman <= thresholdsData[0]?.maxKedalaman ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.kedalaman}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">m</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.kedalaman}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">m</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("nitrat") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Nitrat
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.nitrat <= thresholdsData[0]?.maxNitrat ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.nitrat}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.nitrat}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("amonia") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      Amonia
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.amonia <= thresholdsData[0]?.maxAmonia ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.amonia}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.amonia}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("cod") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      COD
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.cod <= thresholdsData[0]?.maxCod ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.cod}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.cod}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}

                {isSensorNameIncluded("bod") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      BOD
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.bod <= thresholdsData[0]?.maxBod ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.bod}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.bod}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}


                {isSensorNameIncluded("tss") ? (
                  <div className=" bg-[#DAEEFE] border-2 border-[#C3E5FF] border-solid rounded-md shadow-charts py-1 flex flex-col items-center justify-center">
                    <p className="text-center m-0 text-xs text-slate-600">
                      TSS
                    </p>
                    {/* <p className="font-semibold text-3xl m-0 mb-2"> */}
                    {loggerDataByUID[0]?.tss <= thresholdsData[0]?.maxTss ? (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-black m-0">
                          {loggerDataByUID[0]?.tss}
                        </p>
                        <p className="text-xs text-black ml-1 m-0">mg/L</p>
                      </div>
                    ) : (
                      <div className="flex items-end">
                        <p className="font-semibold text-3xl text-red-600 m-0">
                          {loggerDataByUID[0]?.tss}
                        </p>
                        <p className="text-xs text-red-600 ml-1 m-0">mg/L</p>
                      </div>
                    )}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}



              </div>


            </div>

            <hr className="mt-4" />

            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-1 mt-4">
              {isSensorNameIncluded("suhu") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Suhu"
                    selectedKey="suhu"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxSuhu || []}
                    // min={initialMinMax?.suhu?.min}
                    // max={initialMinMax?.suhu?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxSuhu}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Suhu dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {/* {isSensorNameIncluded("dhl") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Daya Hantar Listrik"
                    selectedKey="dhl"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxDhl || []}
                    min={initialMinMax?.dhl?.min}
                    max={initialMinMax?.dhl?.max}
                    height={156}
                    textColor="#041527"
                  />
                  <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxDhl}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data DHL dalam 24 jam: {percentageData()}%
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )} */}

              {isSensorNameIncluded("tds") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="TDS"
                    selectedKey="tds"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxTds || []}
                    // min={initialMinMax?.tds?.min}
                    // max={initialMinMax?.tds?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxTds}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data TDS dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("do") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="DO"
                    selectedKey="do"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxDo || []}
                    // min={initialMinMax?.do?.min}
                    // max={initialMinMax?.do?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxDo}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Do dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}


              {isSensorNameIncluded("pH") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="pH"
                    selectedKey="ph"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxpH || []}
                    thresholdsValue2={thresholdsData[0]?.minpH || []}
                    // min={
                    //   initialMinMax?.ph?.min <= 2
                    //     ? initialMinMax?.ph?.min
                    //     : initialMinMax?.ph?.min - 2
                    // }
                    // max={
                    //   initialMinMax?.ph?.max >= 14
                    //     ? initialMinMax?.ph?.max
                    //     : initialMinMax?.ph?.max + 1
                    // }
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.minpH} - {thresholdsData[0]?.maxpH}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">
                      Pemenuhan data pH dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("turbidity") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Turbidity"
                    selectedKey="turbidity"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxTurbidity || []}
                    // min={initialMinMax?.turbidity?.min}
                    // max={initialMinMax?.turbidity?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxTurbidity}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Turbidity dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}


              {isSensorNameIncluded("kedalaman") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Kedalaman"
                    selectedKey="kedalaman"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxKedalaman || []}
                    // min={initialMinMax?.kedalaman?.min}
                    // max={initialMinMax?.kedalaman?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxKedalaman}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Kedalaman dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("nitrat") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Nitrat"
                    selectedKey="nitrat"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxNitrat || []}
                    // min={initialMinMax?.nitrat?.min}
                    // max={initialMinMax?.nitrat?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxNitrat}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Nitrat dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("amonia") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="Amonia"
                    selectedKey="amonia"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxAmonia || []}
                    // min={initialMinMax?.amonia?.min}
                    // max={initialMinMax?.amonia?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxAmonia}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data Amonia dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}


              {isSensorNameIncluded("cod") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="COD"
                    selectedKey="cod"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxCod || []}
                    // min={initialMinMax?.cod?.min}
                    // max={initialMinMax?.cod?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxCod}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data COD dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("bod") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="BOD"
                    selectedKey="bod"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxBod || []}
                    // min={initialMinMax?.bod?.min}
                    // max={initialMinMax?.bod?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxBod}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data BOD dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("tss") ? (
                <div className="border-solid border-slate-200 shadow-charts overflow-scroll hide-scrollbar">
                  <LineChartApex
                    title="TSS"
                    selectedKey="tss"
                    label="datetime"
                    chartData={slicedData || []}
                    thresholdsValue1={thresholdsData[0]?.maxTss || []}
                    // min={initialMinMax?.tss?.min}
                    // max={initialMinMax?.tss?.max}
                    height={156}
                    textColor="#041527"
                  />
                  {/* <div className="flex justify-between">
                    <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxTss}</p>
                    <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                    <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                      Pemenuhan data TSS dalam 24 jam: {percentageData()}%
                    </p>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <p className="text-center text-xs text-slate-400 m-0">
              Copyright © Buana Biru Teknindo
            </p>
          </div>
        )}
      </div>


    </>
  );
}

export default DisplayPage;
